import React from "react";
import number1 from '../assets/01.png';
import number01 from '../assets/01.svg';
import number2 from '../assets/02.svg';
import number3 from '../assets/03.svg';
import number4 from '../assets/04.svg';
import number5 from '../assets/05.svg';
import "./CustomLogoMaker.css";

const CustomLogoMaker = () => {
  const cardData = [
    {
      logo: number01,
      caption: "Industry Selection",
      description: "Choose your industry and enter your company name.",
    },
    {
      logo: number2,
      caption: "Font Customization",
      description: "Choose the font style that best represents your brand.",
    },
    {
      logo: number3,
      caption: "Layout Selection",
      description: "Explore and select your desired logo layout.",
    },
    {
      logo: number4,
      caption: "Logo Generation",
      description: "View logos generated based on your preferences.",
    },
    {
      logo: number5,
      caption: "Download and Use",
      description: "Download your logo and start using it immediately.",
    }
  ];

  return (
    <div className="customSectionWrapper ">
      <h2 className="sectionHeading text-center">Getting Started with Our Custom Logo Maker<span className="dotStyle">.</span></h2>
      <div className="servicesStepsWrapper row row-cols-1 row-cols-md-2  " style={{columnGap:'9.35rem', justifyContent:'flex-start', paddingLeft:'7.8rem'}}>
        {cardData.map((card, index) => (
          <div key={index} className=" mb-4 p-2" style={{width:'25rem'}}>
            <div className="card d-flex flex-row align-items-center border-none" style={{border:'none'}}>
              <img
                src={card.logo}
               
                alt={card.caption}
                style={{marginRight:'0.75rem', width:'3.0625rem', height:'3.25rem'}}
              />
              <h5 className="cardTitle">{card.caption}</h5>
            </div>
            <p className="cardText " style={{width:'100%'}}>{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomLogoMaker;

import React, { useState } from 'react';
import './MakeLogo.css';
import styles1 from "../pages/CustomDesignServices.module.css";
import nextIcon from "../assets/nextIcon.svg";
import automatic from "../assets/automatic  and transportation.png";
import badge from "../assets/badge.png";
import youcompanyname from "../assets/youcompanyname.png";
import star from "../assets/star.png";
import automaticCar from "../assets/automatic.png";
import oneone2 from "../assets/+112.png";
import styles from "../pages/Homepage.module.css";

const SampleLogosCard = ({ backgroundColor, imgSrc, imgWidth, imgHeight }) => {
    return (
        <div className={styles1.CTACardWrapper} style={{ backgroundColor: backgroundColor }}>

            <img src={imgSrc} style={{ width: imgWidth, height: imgHeight }} />

        </div>
    );
}

const cardData = [
    {
        backgroundColor: "rgba(208, 230, 209, 1)",
        imgSrc: automatic,
        imgWidth: '4.0625rem',
        imgHeight: '3.0625rem'
    },
    {
        backgroundColor: "rgba(15, 66, 88, 1)",
        imgSrc: badge,
        imgWidth: '3.8125rem',
        imgHeight: '2.3125rem'
    },
    {
        backgroundColor: "rgba(217, 217, 192, 1)",
        imgSrc: youcompanyname,
        imgWidth: '3.75rem',
        imgHeight: '2.3125rem'
    },
    {
        backgroundColor: "rgba(207, 240, 255, 1)",
        imgSrc: star,
        imgWidth: '3.6875rem',
        imgHeight: '3.0625rem'
    },
    {
        backgroundColor: "rgba(205, 223, 251, 1)",
        imgSrc: automaticCar,
        imgWidth: '4.0625rem',
        imgHeight: '2.125rem'
    },
    {
        backgroundColor: "#FFF0F5",
        imgSrc: oneone2,
        imgWidth: '1.9rem',
        imgHeight: '0.9rem'
    },
]
const CustomLogosCTA = ({ sectionHeading, sectionPara, marginTop, marginBottom }) => {


    return (
        <div className="whyChooseWrapper text-center d-flex flex-column align-items-center" style={{ paddingTop: marginTop, paddingBottom: marginBottom }}>
            <h2 className={styles1.CTAsectionHeading} style={{ marginBottom: '0.88rem'  }}> {sectionHeading ? sectionHeading : "Ready to Get Your Perfect Logo"}<span className="questionMark" style={{fontSize: '1.8125rem'}}>?</span></h2>
            <p className="sectionDescription" style={{ width: '38.3125rem' }}>
                {sectionPara ? sectionPara : "Transform your brand's identity with our custom logo design service. Our expert designers will work closely with you to create a logo that captures the essence of your business and sets you apart from the competition."}
            </p>

            <div className={styles1.stepsButtonsContainer}>
                {cardData.map((item, index) => (
                    <React.Fragment key={index}>
                        <SampleLogosCard
                            backgroundColor={item.backgroundColor}
                            imgSrc={item.imgSrc}
                            imgHeight={item.imgHeight}
                            imgWidth={item.imgWidth}
                        />
                    </React.Fragment>
                ))}
            </div>

            <button className={[styles.masterPrimaryButton, styles.buttonText1].join(' ')} onClick={() => navigate('/')} style={{width: '9.3125rem', height: '2.625rem', padding:0, marginTop:'1.62rem'}}>
            <span className={styles.buttonText} style={{fontSize:'0.875rem'}}>Get Started Now</span>
          </button>

        </div>

    );
};

export default CustomLogosCTA;

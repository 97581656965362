
import React, { useState, useEffect } from "react";
import Stars from "../assets/stars.png";
import "./MakeLogo.css";
import styles from "../pages/CustomDesignServices.module.css";

const ScrollSection = ({ seoSectionData }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (seoSectionData)
            setData(seoSectionData);

        console.log('seoSectionData', seoSectionData);
    }, [seoSectionData]);

    return (
        <div className={styles.scrollSection}>
            <div className={styles.scrollContent}>
                <div className="d-flex flex-column justify-content-start gap-3">
                    <div className={styles.contentBox}>
                        {data && data.length > 0 ? (
                            <>
                                <h2 className={styles.contentHeading}>{data[0]?.heading || ""}</h2>
                                <p className={styles.contentPara}>{data[0]?.description || ""}</p>
                            </>
                        ) : (
                            <>
                                <h2 className={styles.contentHeading}>What are the Services of Logo Design<span className="questionMark" style={{ fontSize: '1.5rem' }}>?</span></h2>
                                <p className={styles.contentPara}>
                                    Logo design services website offers a range of valuable offerings that can elevate your brand identity and make a lasting impression on your audience. Here's a breakdown of some of the key services they provide:
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Custom Logo Creation: &nbsp;</h3>
                                    Professional logo design services
                                    specialize in creating unique, eye-catching logos tailored to your brand's
                                    personality, values, and target audience. Whether you're a start-up looking to
                                    establish your identity or an established company in need of a refresh, they can craft a logo that reflects who you are.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Brand Identity Development: &nbsp;</h3>
                                    Your logo is just one aspect of your brand identity. These services often offer comprehensive brand identity development, which includes designing complementary visual elements such as color palettes, typography, and brand guidelines. This ensures consistency across all your marketing materials and helps build brand recognition.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Rebranding and Logo Redesign: &nbsp;</h3>
                                    If your current logo is outdated or no longer resonates with your target audience, a designing service can help you rebrand. Whether it's a subtle refresh or a complete overhaul, they'll work with you to create a new logo that aligns with your evolving brand identity and goals.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Logo Optimization for Different Platforms: &nbsp;</h3>
                                    A well-designed logo should look great across various platforms and mediums, from websites and social media profiles to business cards and merchandise. We can optimize your logo for different formats and sizes, ensuring it maintains its visual appeal and clarity wherever it's displayed.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}> Professional Consultation and Support: &nbsp;</h3>
                                    Professional Consultation and Support: Beyond the design process, we also provide expert advice and guidance to help you make informed decisions about your brand's visual identity. Whether you're brainstorming ideas, reviewing concepts, or finalizing designs, they'll be there to support you every step of the way.
                                </p>

                                <p className={styles.contentPara}>
                                    So, logo design services offered by TheLogoMaker.ai go far beyond just creating a pretty picture. They play a crucial role in shaping your brand's identity, increasing recognition, and making a lasting impression on your audience.
                                </p>
                            </>
                        )}
                    </div>

                    <div className={styles.contentBox}>
                        {data && data.length > 0 ? (
                            <>
                                <h2 className={styles.contentHeading}>{data[2]?.heading || ""}</h2>
                                <p className={styles.contentPara}>{data[2]?.description || ""}</p>
                            </>
                        ) : (
                            <>
                                <h2 className={styles.contentHeading}>What Makes a Succesful Logo<span className="questionMark" style={{ fontSize: '1.5rem' }}>?</span></h2>
                                <p className={styles.contentPara}>
                                    A successful logo is more than just a pretty design—it's a powerful symbol that communicates your brand's identity and resonates with your audience on a deep level. But what exactly sets apart a successful logo from the rest?
                                </p>
                                <p className={styles.contentPara}>
                                    Let's explore the key elements that contribute to the success of a logo:
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Memorability: &nbsp;</h3>
                                    Memorability: A successful logo is memorable and instantly recognizable. It leaves a lasting impression on viewers, making your brand stand out in their minds amidst a sea of competitors. Memorable logos often feature unique design elements, distinctive colors, or clever symbolism that captivates attention and leaves a lasting impression.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Simplicity: &nbsp;</h3>
                                    The best logos are often the simplest. A successful logo conveys your brand's essence in a clear and concise manner without unnecessary complexity or clutter. Simple logos are easier to recognize, scale, and reproduce across various mediums, ensuring consistency and versatility in your brand's visual identity.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Relevance: &nbsp;</h3>
                                    A successful logo is relevant to your brand and resonates with your target audience. It reflects your brand's values, personality, and offerings, forging a connection with consumers on an emotional level. Whether through subtle symbolism or straightforward imagery, your logo should speak directly to the desires and aspirations of your audience.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Versatility: &nbsp;</h3>
                                    A successful logo is versatile and adaptable to different contexts and applications. It looks great whether it's displayed on a website, printed on merchandise, or embroidered on apparel. Versatile logos are designed with scalability in mind, ensuring they maintain their integrity and visual impact across various sizes and mediums.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}> Timelessness: &nbsp;</h3>
                                    While trends come and go, successful logos stand the test of time. They transcend fleeting fads and remain relevant and impactful for years to come. Timeless logos are characterized by classic design principles, enduring symbolism, and universal appeal, ensuring they remain effective and recognizable throughout your brand's evolution.
                                </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}> Uniqueness: &nbsp;</h3>
                                    Lastly, a successful logo is unique and sets your brand apart from competitors. It distinguishes your brand in a crowded marketplace, helping you carve out a distinct identity and build brand recognition. Whether through innovative design elements, bold color choices, or unconventional typography, your logo should be unmistakably yours.
                                </p>

                                <p className={styles.contentPara}>
                                    Our custom logo design service is committed to ensuring that the logo we create for you embodies all the essential features of a successful design. With our expert team of designers, we meticulously craft logos that are not only memorable but also simple, relevant, versatile, timeless, and unique.
                                </p>
                                <p className={styles.contentPara}>
                                    We understand the importance of creating a logo that resonates with your audience and sets your brand apart from the competition. By choosing our service, you can trust that your logo will be a powerful representation of your brand's identity, leaving a lasting impression on your customers.
                                </p>
                            </>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-start gap-3">
                    <div className={styles.contentBox}>
                    {data && data.length > 0 ? (
                            <>
                                <h2 className={styles.contentHeading}>{data[1]?.heading || ""}</h2>
                                <p className={styles.contentPara}>{data[1]?.description || ""}</p>
                            </>
                        ) : (
                            <>
                                <h2 className={styles.contentHeading}>What are the 4 Types of Logo Design<span className="questionMark" style={{ fontSize: '1.5rem' }}>?</span></h2>
                                <p className={styles.contentPara}>When it comes to creating a logo for your business, there's more than one approach to consider. Logo design encompasses various styles and types, each with its own unique characteristics and applications. </p>
                                <p className={styles.contentPara}>Following are the four primary types of logo design: </p>

                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Wordmark Logos:&nbsp;</h3>
                                    Also known as logotypes, wordmark logos consist of the company's name or initials stylized
                                    in a unique font or typography. These logos rely solely on text to convey the brand's identity and are often
                                    used by companies with distinctive or memorable names.
                                    <br></br>
                                    <h3 className={styles.contentSubHeading}>Examples&nbsp;</h3>
                                    include Coca-Cola, Google, and Disney.
                                </p>


                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Symbol or Icon Logos:&nbsp;</h3>
                                    Symbol or icon logos feature a graphic symbol or emblem that represents the brand without relying on text. These symbols are often abstract or simplified representations of the company's values, products, or services. Icon logos are highly versatile and instantly recognizable, making them ideal for companies aiming for global recognition.
                                    <br></br>
                                    <h3 className={styles.contentSubHeading}>Famous examples &nbsp;</h3>
                                    include the Nike swoosh, Apple's apple, and Twitter's bird icon.
                                </p>


                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Combination Mark Logos:&nbsp;</h3>
                                    As the name suggests, combination mark logos combine both text and symbol elements to create a unified logo. This type of logo design offers the best of both worlds, allowing companies to reinforce their brand name while also incorporating a memorable visual symbol. Combination mark logos provide flexibility and can adapt to various marketing materials and platforms.
                                    <br></br>

                                    Well-known<h3 className={styles.contentSubHeading}>&nbsp;combination mark logos include</h3>  Starbucks, Burger King, and Adidas.
                                </p>


                                <p className={styles.contentPara}>
                                    <h3 className={styles.contentSubHeading}>Emblem Logos:&nbsp;</h3>
                                    Emblem logos feature text enclosed within a symbol or icon, creating a cohesive and integrated design. These logos often have a traditional or vintage aesthetic, resembling badges or seals. Emblem logos are commonly associated with organizations, clubs, or institutions, as they convey authority, prestige, and heritage.
                                    <br></br>
                                    <h3 className={styles.contentSubHeading}>Examples &nbsp;</h3>
                                    Harley-Davidson, BMW, and Starbucks' original logo.
                                </p>


                                <p className={styles.contentPara}>
                                    Each type of logo design has its own strengths and suitability depending on factors such as the company's industry, target audience, and brand identity. By understanding the characteristics of each type, you can make informed decisions when creating or redesigning your logo to effectively represent your brand and resonate with your audience.
                                </p>



                                <h2 className={styles.contentHeading}>What are the Best Dimensions for a Logo<span className="questionMark" style={{ fontSize: '1.5rem' }}>?</span></h2>
                                <p className={styles.contentPara}>When it comes to determining the dimensions for your logo, there are some standard ranges to consider. Typically, logos are designed within a square or rectangular aspect ratio, with dimensions ranging from 100 pixels to 1000 pixels for digital use and from 1 inch to 10 inches for print. However, these dimensions can vary based on the complexity of your design and its intended usage. </p>
                                <p className={styles.contentPara}>It's essential to prioritize scalability, ensuring your logo remains crisp and recognizable across various sizes. Aim for simplicity, avoiding intricate details that may become lost when scaled down. Additionally, consider responsive design principles to ensure your logo adapts seamlessly to different screen sizes and orientations.</p>
                            </>
                        )}
                    </div>

                    {data && data.length > 0 && data[3] && (

                        <div className={styles.contentBox}>
                            <h2 className={styles.contentHeading}>{data[3]?.heading || ""}</h2>
                            <p className={styles.contentPara}>{data[3]?.description || ""}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ScrollSection;
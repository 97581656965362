import React from 'react';
import PropTypes from 'prop-types';
import styles from './Pagination.module.css'; // Import your CSS styles
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const CustomPagination = ({ count, page, onChange }) => {
  const handlePageChange = (newPage) => {
    console.log(newPage,'newPage');
    if (onChange) {
      onChange(newPage);
    }
  };

  const renderPages = () => {
    const pages = [];
    for (let i = 1; i <= count; i++) {
      const isActive = i === page;
      pages.push(
        <button
          key={i}
          className={`${styles.pageButton} ${isActive ? styles.activePage : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <div className={styles.paginationContainer}>
      <button
        className={styles.arrowButton}
        onClick={() => handlePageChange(Math.max(page - 1, 1))}
        disabled={page === 1}
      >
        <ArrowLeftIcon style={{fontSize:'2rem', fontWeight:'400'}}/>
      </button>
      {renderPages()}
      <button
        className={styles.arrowButton}
        onClick={() => handlePageChange(Math.min(page + 1, count))}
        disabled={page === count}
      >
        <ArrowRightIcon style={{fontSize:'2rem', fontWeight:'400'}}/>
      </button>
    </div>
  );
};

CustomPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomPagination;

import React from "react";
import styles from "../pages/Homepage.module.css";
import "./TwoColumnsSection.css";
import "./MakeLogo.css";
import {useNavigate }from 'react-router-dom';

const TwoColumnsSection = () => {
  const navigate = useNavigate();
  return (
 
  
    <div className="col-sm-6 FAQWraper px-5" style={{paddingTop:'3.75rem', paddingBottom:'3.75rem'}}>
          <div className="row align-items-center justify-content-center" style={{gap:'1.38rem'}}>
           
              <h3 className="ctoHeading" >
                <span className="spanStyle">Start Designing</span>
             
              {" "}From Ideas to Logos!
              </h3>
      

            <div className="d-flex justify-content-center" style={{flex:1}}>
            <button className={[styles.masterPrimaryButton, styles.buttonText1].join(' ')} style={{width: '11.3125rem',height: '2.625rem', marginTop:0, borderRadius:'0.3125rem'}} onClick={() => navigate('/')}>
              <span className={styles.buttonText} style={{fontSize:'0.875rem'}}>Make your logo</span>
            </button>
            </div>
          </div>
        </div>
      );
      };

      export default TwoColumnsSection ;
import React, { createContext, useState , useContext} from 'react';

// Create the context
const LogoContext = createContext();

const LogoProvider = ({ children }) => {
  // State to store the preferences
  const [selectedFonts, setSelectedFonts] = useState([]);
  const [selectedLayouts, setSelectedLayouts] = useState([]);
  const [logoStyles, setLogoStyles] = useState([]);
  const [logoText, setLogoText] = useState('');
  const [logoIndustry, setLogoIndustry] = useState('');
 
  const resetContextVariables = () => {
    setSelectedFonts([]); // Reset to initial state
    setSelectedLayouts([]); // Reset to initial state
    setLogoStyles([]); // Reset to initial state
    setLogoText(''); // Reset to initial state
    setLogoIndustry(''); // Reset to initial state
};


  return (
    <LogoContext.Provider value={{ selectedFonts, setSelectedFonts, selectedLayouts, setSelectedLayouts, 
    logoStyles, setLogoStyles, logoText, setLogoText, logoIndustry, setLogoIndustry, resetContextVariables}}>
      {children}
    </LogoContext.Provider>
  );
};

export const useLogoContext = () => {
    const context = useContext(LogoContext);
    if (!context) {
      throw new Error('useLogo must be used within a LogoProvider');
    }
    return context;
  };

export { LogoContext, LogoProvider };

import React, { useState, useEffect, useContext } from 'react';
import styles from "./Homepage.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Animation1 from "../assets/Hero0.png";
import Animation2 from "../assets/Hero1.png";
import Animation3 from "../assets/Hero2.png";
import Animation4 from "../assets/Hero3.png";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrowDown from "../assets/arrowDown.png";
import { LogoContext } from '../contexts/LogoGeneratorContext';

import Info from "../assets/info 1.svg";
import { Carousel } from 'react-bootstrap';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Form.css';
import AnimatedSVG from '../components/AnimatedSVG';
import CustomDropdown from '../components/CustomDropdown';
const options = [
  { value: 'Automotives and Transportation', label: 'Automotives and Transportation' },
  { value: 'Business & Consulting', label: 'Business & Consulting' },
  { value: 'Food, Beverage, Restaurant', label: 'Food, Beverage, Restaurant' },
  { value: 'Travel & Holidays', label: 'Travel & Holidays' },
  { value: 'IT & Science', label: 'IT & Science' },
  { value: 'Marketing', label: 'Marketing' }
];

const Frame5 = ({ scrollToLogoCards }) => {
  const { logoText, setLogoText, logoIndustry, setLogoIndustry } = useContext(LogoContext);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Transition speed in milliseconds
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000, // Interval between slides in milliseconds
    arrows: false, // Hide navigation arrows
  };
  const navigate = useNavigate();
  const [industry, setIndustry] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (industry && companyName) {
      setError(false);
      return;
    }
  }, [industry, companyName, error]);

  const handleIndustryChange = (selectedOption) => {
    setIndustry(selectedOption);
    setLogoIndustry(selectedOption);
  };

  const handleProceed = () => {
    if (!industry || !companyName) {
      setError(true);
      return;
    }

    setError(false);
    navigate('/steps');
  };
  return (
    <div className={`${styles.imagePlaceholderParent} col-xs-6 col-sm-12 col-md-12`}>


      <Row className={styles.wrappingContainer}
        style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
          paddingTop: '4.52rem', gap: '7.44rem'
        }}>


        <div className={styles.heroLeftContainer} style={{ paddingLeft: 0, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', width: '29.1875rem' }}>
          <div className={styles.createAProfessionalContainer}>
            <h1 className={styles.createAProfessionalLogoIn}>
              Make a free logo design in minutes with AI
              <span className={styles.span}>.</span>
            </h1>
          </div>
          <div className={styles.paragraph}>
            LogoMaker can make your big idea a beautiful reality.
          </div>


          <div

            style={{
              fontFamily: 'Poppins',
              borderRadius: '5px',
              lineHeight: '1.125rem',

              marginTop: '3.12rem',


              fontWeight: 400,
              fontSize: '0.875rem',
              color: '#878787',
              width: '100%'
            }}>
            <CustomDropdown options={options} placeholder="Choose Industry"
              onChange={handleIndustryChange} />

          </div>

          <Form.Control
            type="text"
            placeholder="Company Name"
            className={styles.underlineInput}
            value={companyName}
            maxLength={30} 
            onChange={(e) => {
              setCompanyName(e.target.value);
              setLogoText(e.target.value);
            }
            }
            style={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              marginTop: '0.88rem',
              // width: '27.5rem',
              fontSize: '0.875rem',

            }}
          />

          {error && <div style={{ color: 'red', marginTop: '0.5rem' }}>Please fill out all fields.</div>}
          <button
            className={[styles.masterPrimaryButton, styles.buttonText1].join(' ')}
            onClick={handleProceed}
          >
            <span className={styles.buttonText}>Make your logo</span>
          </button>

          <div className={styles.paragraph1}>
            <img src={Info} alt='warning-icon' style={{ width: "1.25rem", height: "1.25rem" }}></img>
            No Credit Card Required</div>

        </div>



        <div className={styles.animationContainer} style={{ paddingLeft: 0, flex: 1, width: '38.36294rem', height: '25.13113rem', overflow: 'hidden' }}>


          <Slider {...settings}>
            <div className={styles.customPadding}>
              <img className={styles.groupChild} alt="Cosmetic products" src={Animation1} />
            </div>
            <div className={styles.customPadding}>
              <img className={styles.groupChild} alt="Construction items" src={Animation2} />
            </div>
            <div className={styles.customPadding}>
              <img className={styles.groupChild} alt="Cups and paper bag" src={Animation3} />
            </div>
            <div className={styles.customPadding}>
              <img className={styles.groupChild} alt="Brioche brochures" src={Animation4} />
            </div>
          </Slider>



        </div>
      </Row>

      <div className="arrow-container">
        <img src={arrowDown} alt="Down Arrow" onClick={scrollToLogoCards} className="arrow-image" style={{ cursor: 'pointer' }} />
      </div>

    </div >
  );
};

export default Frame5;

import React, { useState, useEffect } from 'react';
import './MakeLogo.css';
import styles1 from "../pages/CustomDesignServices.module.css";
// import nextIcon from "../assets/nextIcon.svg";
import logo1 from "../assets/write-logo.svg";
import logo2 from "../assets/security-payment 1.svg";
import logo3 from "../assets/construction-plan 1.svg";
import logo4 from "../assets/design 1.svg";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
// import usePreventBodyScroll from "./usePreventBodyScroll";
import "./hideScrollbar.css";
import { LeftArrow, RightArrow } from "./arrows";
import { element } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../config/config';

const CustomLogosSteps = ({ stepsSectionData, marginTop, marginBottom }) => {
  const [activeStep, setActiveStep] = useState('');
  const [data, setData] = useState({});
  const [nestedData, setNestedData] = useState([]);
  const navigate = useNavigate();


  const handleButtonClick = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (stepsSectionData) {
      setData(stepsSectionData);
      setNestedData(stepsSectionData?.steps);
      setActiveStep(stepsSectionData?.steps[0]?.heading)
    }
    console.log('stepsSectionData', stepsSectionData);
  }, [stepsSectionData]);

  const renderImage = () => {
    const activeStepData = nestedData.find(step => step.heading === activeStep);

    if (activeStepData) {
      return (
        <>
          <img
            src={`${baseURL}/uploads/${activeStepData?.icon_path}`}
            alt={activeStepData.icon_alt_text}
            className={styles1.stepsIcon}
          
          />
        </>
      );
    }
  }

  const renderContent = () => {
    const activeStepData = nestedData.find(step => step.heading === activeStep);

    if (activeStepData) {
      return (
        <>
          <h3 className={styles1.stepHeading}>{activeStepData?.subheading}</h3>
          <p className={styles1.stepPara}>{activeStepData?.description}</p>
          <button className={styles1.stepButton} onClick={() => navigate(activeStepData?.button_link || '/')}>{activeStepData?.button_text}</button>
        </>
      );
    }


    // switch (activeStep) {
    //   case '1. Brief':
    // return (
    //   <>
    //     <h3 className={styles1.stepHeading}>Submit Your Brief</h3>
    //     <p className={styles1.stepPara}>Fill in the order form with your design requirements or ideas.</p>
    //     <button className={styles1.stepButton}>Place an Order</button>
    //   </>
    // );
    // case '2. Payment':
    //   return (
    //     <>
    //       <h3 className={styles1.stepHeading}>Make a Payment</h3>
    //       <p className={styles1.stepPara}>Pay a 50% advance to get our designers started on your design.</p>
    //       <button className={styles1.stepButton}>Place an Order</button>
    //     </>
    //   );
    // case '3. Designing':
    //   return (
    //     <>
    //       <h3 className={styles1.stepHeading}>We Design Your Logo</h3>
    //       <p className={styles1.stepPara}>Sit back and relax while we create your logo according to your specifications.</p>
    //       <button className={styles1.stepButton}>Place an Order</button>
    //     </>
    //   );
    // case '4. Delivery':
    //   return (
    //     <>
    //       <h3 className={styles1.stepHeading}>Recieve Your Logo</h3>
    //       <p className={styles1.stepPara}>Get your logo files in your preferred format within your specified timeframe.</p>
    //       <button className={styles1.stepButton}>Place an Order</button>
    //     </>
    //   );
    //default:
    //return null;
  };

  return (
    <div className="customStepsWrapper text-center" style={{ paddingTop: marginTop, paddingBottom: marginBottom }}>
      <h2 className="sectionHeading" style={{ marginBottom: '1rem' }}> {data.heading ? data.heading : "Get Your Custom Logo in Four Easy Steps"}<span className="questionMark">.</span></h2>
      <p className="sectionDescription">
        {data.subheading ? data.subheading : "We offer amazing ways to work with our community of professional designers."}
      </p>
      <div className={styles1.stepsWrapper}>
        <div className={styles1.stepsButtonsContainer} >
          {/* <div style={{ width: "100%", overflow: "auto", display: "flex", justifyContent:'center', gap:' 0.75rem', paddingLeft:'29.5rem' }}> */}
          {/* <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
          > */}
          {/* {['1. Brief', '2. Payment', '3. Designing', '4. Delivery'].map((step) => ( */}
          {nestedData?.map((step, index) => (
            <React.Fragment key={index}>
              <button
                className={`${styles1.stepsButton} ${activeStep === step.heading ? styles1.activeButton : ''}`}
                onClick={() => handleButtonClick(step.heading)}
              >
                {step.heading}
              </button>
              {/* {step !== '4. Delivery' && <img src={nextIcon} className={styles1.nextIcon} alt="Next" />} */}
            </React.Fragment>
          ))}
          {/* </ScrollMenu> */}
        </div>

        <div className={styles1.InnerContainer}>
          <div className={styles1.stepTextContainer}>
            {renderContent()}

          </div>
          <div
            className={styles1.stepIconWrapper}
          >

            {renderImage()}
          </div>
        </div>
      </div>

    </div>
  );
};

export default CustomLogosSteps;

import React, { useMemo, FC, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { LogoContext } from '../contexts/LogoGeneratorContext';
import styled from 'styled-components';
import axios from 'axios';
import { baseURL } from '../config/config';
import icon from '../assets/GD_automobile-and-transportation-01.svg';
//import { ReactComponent as Logo } from '../assets/GD_automobile-and-transportation-01.svg';
//import { ReactComponent as Logo } from '../assets/Travel & Holidays-02_Test.svg';
import Logo from '../assets/GD_automobile-and-transportation-01.svg';
import { ReactComponent as Logo1 } from '../assets/BadgeTravel/Travel & Holidays-01.svg';
import { ReactComponent as Logo2 } from '../assets/BadgeTravel/Travel & Holidays-02.svg';
import { ReactComponent as Logo3 } from '../assets/BadgeTravel/Travel & Holidays-03.svg';
import { ReactComponent as Logo4 } from '../assets/BadgeTravel/Travel & Holidays-04.svg';
import { ReactComponent as Logo5 } from '../assets/BadgeTravel/Travel & Holidays-05.svg';
import { ReactComponent as Logo6 } from '../assets/BadgeTravel/Travel & Holidays-06.svg';
import { ReactComponent as Logo7 } from '../assets/BadgeTravel/Travel & Holidays-07.svg';
import { ReactComponent as Logo8 } from '../assets/BadgeTravel/Travel & Holidays-08.svg';
import { ReactComponent as Logo9 } from '../assets/BadgeTravel/Travel & Holidays-09.svg';
import { ReactComponent as Logo10 } from '../assets/BadgeTravel/Travel & Holidays-10.svg';
import { ReactComponent as Logo11 } from '../assets/BadgeTravel/Travel & Holidays-11.svg';
import { ReactComponent as Logo12 } from '../assets/BadgeTravel/Travel & Holidays-12.svg';
import { ReactComponent as Logo13 } from '../assets/BadgeTravel/Travel & Holidays-13.svg';
import { ReactComponent as Logo14 } from '../assets/BadgeTravel/Travel & Holidays-14.svg';
import { ReactComponent as Logo15 } from '../assets/BadgeTravel/Travel & Holidays-15.svg';
import { ReactComponent as Logo16 } from '../assets/BadgeTravel/Travel & Holidays-16.svg';
import { ReactComponent as Logo17 } from '../assets/BadgeTravel/Travel & Holidays-17.svg';
import { ReactComponent as Logo18 } from '../assets/BadgeTravel/Travel & Holidays-18.svg';
import { ReactComponent as Logo19 } from '../assets/BadgeTravel/Travel & Holidays-19.svg';
import { ReactComponent as Logo20 } from '../assets/BadgeTravel/Travel & Holidays-20.svg';
import { ReactComponent as Logo21 } from '../assets/BadgeTravel/Travel & Holidays-21.svg';

import { ReactComponent as FoodLogo1 } from '../assets/BadgeFood/Badges_Food & Beverages-01.svg';
import { ReactComponent as FoodLogo2 } from '../assets/BadgeFood/Badges_Food & Beverages-02.svg';
import { ReactComponent as FoodLogo3 } from '../assets/BadgeFood/Badges_Food & Beverages-03.svg';
import { ReactComponent as FoodLogo4 } from '../assets/BadgeFood/Badges_Food & Beverages-04.svg';
import { ReactComponent as FoodLogo5 } from '../assets/BadgeFood/Badges_Food & Beverages-05.svg';
import { ReactComponent as FoodLogo6 } from '../assets/BadgeFood/Badges_Food & Beverages-06.svg';
import { ReactComponent as FoodLogo7 } from '../assets/BadgeFood/Badges_Food & Beverages-07.svg';
import { ReactComponent as FoodLogo8 } from '../assets/BadgeFood/Badges_Food & Beverages-08.svg';
import { ReactComponent as FoodLogo9 } from '../assets/BadgeFood/Badges_Food & Beverages-09.svg';
import { ReactComponent as FoodLogo10 } from '../assets/BadgeFood/Badges_Food & Beverages-10.svg';
import { ReactComponent as FoodLogo11 } from '../assets/BadgeFood/Badges_Food & Beverages-11.svg';
import { ReactComponent as FoodLogo12 } from '../assets/BadgeFood/Badges_Food & Beverages-12.svg';
import { ReactComponent as FoodLogo13 } from '../assets/BadgeFood/Badges_Food & Beverages-13.svg';
import { ReactComponent as FoodLogo14 } from '../assets/BadgeFood/Badges_Food & Beverages-14.svg';
import { ReactComponent as FoodLogo15 } from '../assets/BadgeFood/Badges_Food & Beverages-15.svg';
import { ReactComponent as FoodLogo16 } from '../assets/BadgeFood/Badges_Food & Beverages-16.svg';

import { ReactComponent as AutoLogo1 } from '../assets/BadgeAutomobile/Automobile and Transportaion-01.svg';
import { ReactComponent as AutoLogo2 } from '../assets/BadgeAutomobile/Automobile and Transportaion-02.svg';
import { ReactComponent as AutoLogo3 } from '../assets/BadgeAutomobile/Automobile and Transportaion-03.svg';
import { ReactComponent as AutoLogo4 } from '../assets/BadgeAutomobile/Automobile and Transportaion-04.svg';
import { ReactComponent as AutoLogo5 } from '../assets/BadgeAutomobile/Automobile and Transportaion-05.svg';
import { ReactComponent as AutoLogo6 } from '../assets/BadgeAutomobile/Automobile and Transportaion-06.svg';
import { ReactComponent as AutoLogo7 } from '../assets/BadgeAutomobile/Automobile and Transportaion-07.svg';
import { ReactComponent as AutoLogo8 } from '../assets/BadgeAutomobile/Automobile and Transportaion-08.svg';
import { ReactComponent as AutoLogo9 } from '../assets/BadgeAutomobile/Automobile and Transportaion-09.svg';
import { ReactComponent as AutoLogo10 } from '../assets/BadgeAutomobile/Automobile and Transportaion-10.svg';
import { ReactComponent as AutoLogo11 } from '../assets/BadgeAutomobile/Automobile and Transportaion-11.svg';
import { ReactComponent as AutoLogo12 } from '../assets/BadgeAutomobile/Automobile and Transportaion-12.svg';
import { ReactComponent as AutoLogo13 } from '../assets/BadgeAutomobile/Automobile and Transportaion-13.svg';
import { ReactComponent as AutoLogo14 } from '../assets/BadgeAutomobile/Automobile and Transportaion-14.svg';
import { ReactComponent as AutoLogo15 } from '../assets/BadgeAutomobile/Automobile and Transportaion-15.svg';
import { ReactComponent as AutoLogo16 } from '../assets/BadgeAutomobile/Automobile and Transportaion-16.svg';
import { ReactComponent as AutoLogo17 } from '../assets/BadgeAutomobile/Automobile and Transportaion-17.svg';
import { ReactComponent as AutoLogo18 } from '../assets/BadgeAutomobile/Automobile and Transportaion-18.svg';
import { ReactComponent as AutoLogo19 } from '../assets/BadgeAutomobile/Automobile and Transportaion-19.svg';
import { ReactComponent as AutoLogo20 } from '../assets/BadgeAutomobile/Automobile and Transportaion-20.svg';

import './AnimatedSVG.css';


const loadGoogleFont = (fontName) => {
  const link = document.createElement('link');
  link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(/ /g, '+')}&display=swap`;
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

const travelLogos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo9, Logo10, Logo11, Logo12, Logo13, Logo14, Logo15, Logo16,
  Logo17, Logo18, Logo19, Logo20, Logo21];

const foodLogos = [FoodLogo1, FoodLogo2, FoodLogo3, FoodLogo4, FoodLogo5, FoodLogo6, FoodLogo7, FoodLogo8, FoodLogo9, FoodLogo10,
  FoodLogo11, FoodLogo12, FoodLogo13, FoodLogo14, FoodLogo15, FoodLogo16];

const automobileLogos = [AutoLogo1, AutoLogo2, AutoLogo3, AutoLogo4, AutoLogo5, AutoLogo6, AutoLogo7, AutoLogo8, AutoLogo9, AutoLogo10,
  AutoLogo11, AutoLogo12, AutoLogo13, AutoLogo14, AutoLogo15, AutoLogo16, AutoLogo17, AutoLogo18, AutoLogo19, AutoLogo20];

export const fontCategories = {
  serif: [
    'Merriweather', 'Playfair Display', 'Roboto Slab', 'Lora', 'Slabo 27px',
    'Crimson Text', 'Bitter', 'Alegreya', 'Noto Serif', 'Cardo',
    'Arvo', 'Cormorant Garamond', 'Bree Serif', 'Tinos', 'Zilla Slab',
    'Cinzel', 'EB Garamond', 'Libre Baskerville', 'Spectral', 'Vollkorn'
  ],
  sansSerif: [
    'Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Oswald',
    'Raleway', 'PT Sans', 'Ubuntu', 'Noto Sans', 'Work Sans',
    'Poppins', 'Source Sans Pro', 'Exo 2', 'Titillium Web', 'Nunito Sans',
    'Fira Sans', 'Hind', 'Muli', 'Barlow', 'Cabin'
  ],
  script: [
    'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', 'Courgette',
    'Indie Flower', 'Yellowtail', 'Caveat', 'Kaushan Script', 'Shadows Into Light',
    'Allura', 'Cookie', 'Alex Brush', 'Sacramento', 'Bad Script',
    'Lobster Two', 'Marck Script', 'Parisienne', 'Tangerine'
  ],
  monospaced: [
    'Roboto Mono', 'Courier Prime', 'IBM Plex Mono',
    'Fira Code', 'Inconsolata', 'Anonymous Pro', 'Oxygen Mono', 'VT323',
    'Cousine', 'Cutive Mono', 'Share Tech Mono', 'Ubuntu Mono', 'Nova Mono',
    'Nanum Gothic Coding', 'Press Start 2P', 'PT Mono'
  ],
  display: [
    'Abril Fatface', 'Lobster', 'Baloo 2', 'Bangers', 'Fredericka the Great',
    'Josefin Sans', 'Righteous', 'Amatic SC', 'Luckiest Guy', 'Anton',
    'Chewy', 'Play', 'Patua One',
    'Staatliches', 'Poiret One', 'Ultra', 'Yatra One', 'Fruktur'
  ]
};

const industryMapping = {
  "Automotives and Transportation": 1,
  "Business & Consulting": 2,
  "Food, Beverage, Restaurant": 3,
  "Travel & Holidays": 4,
  "IT & Science": 5,
  "Marketing": 6,

};

const getRandomElements = (array, count) => {
  const shuffled = [...array].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

//
const getRandomElement = (array) => array[Math.floor(Math.random() * array.length)];

const useCallbackRefs = () => {
  const [refs, setRefs] = useState([]);

  const setRef = useCallback((index) => (node) => {
    if (node !== null) {
      setRefs((prevRefs) => {
        const newRefs = [...prevRefs];
        newRefs[index] = node;
        return newRefs;
      });
    }
  }, []);

  return { refs, setRef };
};

const useGenerateBadgeComponents = (memoizedSelectedFonts, memoizedSLogoText, memoizedIndustry) => {
  const [svgUrls, setSvgUrls] = useState([]);
  const [apiLogos, setApiLogos] = useState([]);
  const [iconLogos, setIconLogos] = useState([]);
  const [industryId, setIndustryId] = useState(null);
  //const [svgRefs, setSvgRefs] = useState([]);
  const initialized = useRef(false);
  const [randomFont, setRandomFont] = useState('');
  const [preSelectedFonts, setPreSelectedFonts] = useState([]);
  //const memoizedSLogoText = 'Automotives';
  //const svgRefs = useRef([]);
  const [svgRefs, setSvgRefs] = useState([]);
  const [refsInitialized, setRefsInitialized] = useState(false);
  const industry = industryMapping[memoizedIndustry];
  // const memoizedSLogoText = 'Some';
  // const memoizedSelectedFonts = 'monospaced';

  const { refs, setRef } = useCallbackRefs();
    useEffect(() => {

  console.log('industry',industry);

      if (industry === 1) {
        setApiLogos(automobileLogos);
      }
      else if (industry === 2) {
        setApiLogos(automobileLogos);
      }
      else if (industry === 3) {
        setApiLogos(foodLogos);
      }
      else if (industry === 4) {
        setApiLogos(travelLogos);
      }
      else if (industry === 5) {
        setApiLogos(automobileLogos);
      }
      else if (industry === 6) {
        setApiLogos(automobileLogos);
      }


    }, [apiLogos, industry]);

  useEffect(() => {

  
  }, [apiLogos]);

  const filteredFonts = useMemo(() => {
    return Object.entries(fontCategories)
      .filter(([category]) => memoizedSelectedFonts.includes(category))
      .flatMap(([category, fonts]) => fonts);
  }, [memoizedSelectedFonts, fontCategories]);

  const shuffleArray = (array) => {
    const shuffled = array.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  useEffect(() => {
    filteredFonts.forEach(font => loadGoogleFont(font));
    console.log('filteredLayoutsbadge', filteredFonts.length);
  }, [filteredFonts]);



  useEffect(() => {
    console.log('apiLogos.length:', apiLogos?.length);
    //console.log('svgRefs.current.length:', svgRefs.current.length);
  }, [apiLogos]);



  useEffect(() => {
    if (refs.length > 0) {
     
      setTimeout(() => {
        refs.forEach((ref, index) => {
          if (ref) {
            const textElement = ref.querySelector('text textPath');
            if (textElement) {
              const pathId = textElement.getAttribute('xlink:href').substring(1);
              const pathElement = ref.querySelector(`path#${pathId}`);
              if (pathElement) {
                const currentFill = textElement.style.fill;
                textElement.textContent = memoizedSLogoText.toUpperCase();
                //console.log('randomFont',randomFont);
            
                //textElement.style.fontFamily = randomFont;
                textElement.style.opacity = 1;

                const pathLength = pathElement.getTotalLength();
                let fontSize = 26;
                textElement.setAttribute("font-size", `${fontSize}px`);
                let textLength  = textElement.getComputedTextLength();
           

                while (textLength > pathLength) {
                  fontSize -= 1;
                  textElement.setAttribute("font-size", `${fontSize}px`);
                  textLength = textElement.getComputedTextLength();
                  if (fontSize <= 2) break;
                }

                const startOffsetPercentage = Math.max(0, (pathLength - textLength) / (2 * pathLength)) * 100;
                textElement.setAttribute('startOffset', `${startOffsetPercentage}%`);
              }
            }
          }
        });
      }, 0);
    }
  }, [memoizedSLogoText, randomFont, refs]);

  useEffect(() => {
    if (filteredFonts.length === 0) {
      // Handle case with no fonts
      return;
    }

    const newLogos = apiLogos.map((Icon, index) => {
      const randomFontForThisLogo = getRandomElement(filteredFonts);
      return (
        <div key={index}>
          <Icon ref={setRef(index) }  style={{ fontFamily: randomFontForThisLogo }}/>
        </div>
      );
    });
    setIconLogos(newLogos);
    // Handle newLogos or any other logic here
  }, [apiLogos, filteredFonts, setRef]);

  
  const memoizedIconLogos = useMemo(() => iconLogos, [iconLogos]);
  return iconLogos;


};

export default useGenerateBadgeComponents;
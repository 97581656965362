import React from "react";
import section1 from "../assets/BrandID.png";
import section2 from "../assets/Social-Media-Design.png";
import section3 from "../assets/Merchandizing.png";
import section4 from "../assets/EventBranding.png";
import backElement from "../assets/hp-bg-element.svg";
import "./TwoColumnsSection.css";
import "./MakeLogo.css";

const TwoColumnsSection = () => {
  return (


    <div className="brandWrapper col-xs-12 col-sm-12 col-md-10 col-lg-10 ">
      <div className="row align-items-center justify-content-center twoSectionColGap" style={{ gap: '7.5rem' }}>
        <div className="col-md-6 mb-4 d-flex flex-column  removePadding alignCenterSmall" style={{alignItems:'flex-start', gap: '1.5rem' }}>
          <h3 className="sectionHeading" style={{ width: '34.375rem', marginBottom: '0px' }}>
            Your brand's first impression starts here
            <span className="dotStyle">.</span>
          </h3>
          <p className="cardText" style={{ width: '36.1875rem' }}>
            Use your logo on your website, business cards, and marketing
            materials to establish a strong brand identity and leave a
            lasting impression on your audience. Everything originates from
            an initial logo idea, yet it has the potential to evolve into
            something remarkable.
          </p>
        </div>
        <div className="col-md-6 mb-4 removePadding twoColImageCont" style={{ position: 'relative', flex: 1, height: '26rem', width: '33rem' }}>
          <img src={backElement} alt="Brand Illustration" className="BrandIllustration" style={{ position: 'absolute', width: '26.0435rem', height: '25.97725rem', transform: 'rotate(-354.297deg)', zIndex: 1, top: '-36px', left: '63px' }} />
          <img src={section1} alt="Brand Illustration" className="img-fluid BrandImage" style={{ position: 'absolute', zIndex: 2 }} />
        </div>
      </div>
    </div>
  );
};

export const TwoColumnsSection1 = () => {
  return (

    <div className="brandWrapper brandWrapper col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <div className=" d-none d-md-flex d-lg-flex row align-items-center twoSectionColGap" style={{ gap: '7.5rem' }}>
        <div className="col-md-6 mb-4 removePadding twoColImageCont" style={{ position: 'relative', flex: 1, height: '26rem', width: '33rem' }}>
          <img src={backElement} alt="Brand Illustration" className="BrandIllustration" style={{ position: 'absolute', width: '26.0435rem', height: '25.97725rem', transform: 'rotate(-354.297deg)', zIndex: 1, top: '-36px', left: '63px' }} />
          <img src={section2} alt="Brand Illustration" className="img-fluid BrandImage" style={{ position: 'absolute', zIndex: 2, }} />
        </div>

        <div className="col-md-6 mb-4 d-flex flex-column align-items-start removePadding " style={{ gap: '1.5rem' }}>
          <h3 className="sectionHeading" style={{ width: '29.0625rem', marginBottom: '0px' }}>
            Stand out in the social media crowd
            <span className="dotStyle">.</span>
          </h3>
          <p className="cardText" style={{ width: '36.1875rem' }}>
            Showcase your brand personality on social media platforms like
            Facebook, Instagram, and Twitter by using your logo as your
            profile picture and cover photo.
          </p>
        </div>
      </div>

      <div className="d-lg-none d-md-none d-flex row align-items-center twoSectionColGap" style={{ gap: '7.5rem' }}>


        <div className="col-md-6 mb-4 d-flex flex-column  removePadding alignCenterSmall" style={{alignItems:'flex-start', gap: '1.5rem' }}>
          <h3 className="sectionHeading" style={{ width: '29.0625rem', marginBottom: '0px' }}>
            Stand out in the social media crowd
            <span className="dotStyle">.</span>
          </h3>
          <p className="cardText" style={{ width: '36.1875rem' }}>
            Showcase your brand personality on social media platforms like
            Facebook, Instagram, and Twitter by using your logo as your
            profile picture and cover photo.
          </p>
        </div>

        <div className="col-md-6 mb-4 removePadding twoColImageCont" style={{ position: 'relative', flex: 1, height: '26rem', width: '33rem' }}>
          <img src={backElement} alt="Brand Illustration" className="BrandIllustration" style={{ position: 'absolute', width: '26.0435rem', height: '25.97725rem', transform: 'rotate(-354.297deg)', zIndex: 1, top: '-36px', left: '63px' }} />
          <img src={section2} alt="Brand Illustration" className="img-fluid BrandImage" style={{ position: 'absolute', zIndex: 2, }} />
        </div>
      </div>
    </div>


  );
};


export const TwoColumnsSection2 = () => {
  return (

    <div className="brandWrapper brandWrapper col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <div className="row align-items-center twoSectionColGap " style={{ gap: '7.5rem' }}>



        <div className="col-md-6 mb-4 d-flex flex-column removePadding alignCenterSmall" style={{ alignItems:'flex-start', gap: '1.5rem' }}>
          <h3 className="sectionHeading" style={{ width: '29.0625rem', marginBottom: '0px' }}>
            Turn your logo into a statement piece
            <span className="dotStyle">.</span>
          </h3>
          <p className="cardText" style={{ width: '36.1875rem' }}>
            Print your logo on merchandise such as t-shirts, mugs, and bags
            to promote your brand wherever you go and create a lasting
            impression with your customers. Everything originates from an
            initial logo idea, yet it has the potential to evolve into
            something remarkable.
          </p>
        </div>

        <div className="col-md-6 mb-4 removePadding twoColImageCont" style={{ position: 'relative', flex: 1, height: '26rem', width: '33rem' }}>
          <img src={backElement} alt="Brand Illustration" className="BrandIllustration" style={{ position: 'absolute', width: '26.0435rem', height: '25.97725rem', transform: 'rotate(-354.297deg)', zIndex: 1, top: '-36px', left: '63px' }} />

          <img src={section3} alt="Brand Illustration" className="img-fluid BrandImage" style={{ position: 'absolute', zIndex: 2, }} />
        </div>
      </div>
    </div>
  );
};

export const TwoColumnsSection3 = () => {
  return (

    <div className="brandWrapper brandWrapper col-xs-12 col-sm-12 col-md-10 col-lg-10">
      <div className="d-none d-lg-flex d-md-flex row align-items-center twoSectionColGap" style={{ gap: '7.5rem' }}>
        <div className="col-md-6 mb-4 removePadding twoColImageCont" style={{ position: 'relative', flex: 1, height: '26rem', width: '33rem' }}>
          <img src={backElement} alt="Brand Illustration" className="BrandIllustration" style={{ position: 'absolute', width: '26.0435rem', height: '25.97725rem', transform: 'rotate(-354.297deg)', zIndex: 1, top: '-36px', left: '63px' }} />

          <img src={section4} alt="Brand Illustration" className="img-fluid BrandImage"
            style={{
              position: 'absolute', zIndex: 2,

            }} />
        </div>

        <div className="col-md-6 mb-4 d-flex flex-column align-items-start removePadding" style={{ gap: '1.5rem' }}>
          <h3 className="sectionHeading" style={{ width: '34.0625rem', marginBottom: '0px' }}>
            Make your mark at every event
            <span className="dotStyle">.</span>
          </h3>
          <p className="cardText" style={{ width: '36.1875rem' }}>
            Display your logo prominently on event banners, signage, and
            promotional materials to increase brand visibility and create a
            memorable impression among event attendees.
          </p>
        </div>
      </div>

      <div className="d-lg-none d-md-none d-flex row align-items-center twoSectionColGap " style={{ gap: '7.5rem' }}>


        <div className="col-md-6 mb-4 d-flex flex-column  removePadding alignCenterSmall" style={{ alignItems:'flex-start',gap: '1.5rem' }}>
          <h3 className="sectionHeading" style={{ width: '34.0625rem', marginBottom: '0px' }}>
            Make your mark at every event
            <span className="dotStyle">.</span>
          </h3>
          <p className="cardText" style={{ width: '36.1875rem' }}>
            Display your logo prominently on event banners, signage, and
            promotional materials to increase brand visibility and create a
            memorable impression among event attendees.
          </p>
        </div>
        <div className="col-md-6 mb-4 removePadding twoColImageCont" style={{ position: 'relative', flex: 1, height: '26rem', width: '33rem' }}>
          <img src={backElement} alt="Brand Illustration" className="BrandIllustration" style={{ position: 'absolute', width: '26.0435rem', height: '25.97725rem', transform: 'rotate(-354.297deg)', zIndex: 1, top: '-36px', left: '63px' }} />

          <img src={section4} alt="Brand Illustration" className="img-fluid BrandImage"
            style={{
              position: 'absolute', zIndex: 2,

            }} />
        </div>
      </div>
    </div>




  );
};

export default TwoColumnsSection;


import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./card.module.css";
import selected from '../assets/Selected.svg';
import selection from '../assets/Selection.svg';
import { LogoContext } from '../contexts/LogoGeneratorContext';
import debounce from 'lodash.debounce';



const StyleMapping = {
  0: 'Initials',
  1: 'Icon',
  2: 'Badge',
  3: 'Text',
  4: 'Geometry',
}

const LayoutMapping = {
  0: 'Icon-Top',
  1: 'Icon-Bottom',
  2: 'Icon-Right',
  3: 'Icon-Left',

}

const FontMapping = {
  0: 'serif',
  1: 'sansSerif',
  2: 'script',
  3: 'display',
  4: 'monospaced',
}

const createReverseMapping = (mapping) => {
  if (typeof mapping !== 'object' || mapping === null) {
    console.error("Mapping is not an object:", mapping);
    return {};
  }

  return Object.keys(mapping).reduce((acc, key) => {
    acc[mapping[key]] = Number(key); // Convert keys to numbers
    return acc;
  }, {});
};

function CustomCardCarousel({ cardData, height, display, paddingTop, fontFamily, dataType }) {
  const StyleReverseMapping = createReverseMapping(StyleMapping);
  const LayoutReverseMapping = createReverseMapping(LayoutMapping);
  const FontReverseMapping = createReverseMapping(FontMapping);

  const navigate = useNavigate();
  const { logoStyles, setLogoStyles, selectedFonts, setSelectedFonts, selectedLayouts, setSelectedLayouts } = useContext(LogoContext);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  const [isNearEdge, setIsNearEdge] = useState(false);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const initialSyncDone = useRef(false);

  useEffect(() => {
    if (!activeIndexes || activeIndexes.length === 0) return;

    if (dataType === 'style') {
      const newLogoStyles = activeIndexes.map(index => StyleMapping[index]);
      setLogoStyles(newLogoStyles);
    } else if (dataType === 'layout') {
      const newLayouts = activeIndexes.map(index => LayoutMapping[index]);
      setSelectedLayouts(newLayouts);
    } else if (dataType === 'font') {
      const newFonts = activeIndexes.map(index => FontMapping[index]);
      setSelectedFonts(newFonts);
    }
  }, [activeIndexes, dataType, setLogoStyles, setSelectedFonts, setSelectedLayouts]);


  // useEffect(() => {
  //   if (dataType === 'style' && logoStyles.length > 0) {
  //     const newActiveIndexes = logoStyles.map(style => StyleReverseMapping[style]);
  //     console.log('length',activeIndexes);
  //     setActiveIndexes(newActiveIndexes);
  //   } else if (dataType === 'layout' && selectedLayouts.length > 0) {
  //     const newActiveIndexes = selectedLayouts.map(layout => LayoutReverseMapping[layout]);
  //     console.log('length',selectedLayouts.length );
  //     setActiveIndexes(newActiveIndexes);
  //   } else if (dataType === 'font' && selectedFonts.length > 0) {
  //     const newActiveIndexes = selectedFonts.map(font => FontReverseMapping[font]);
  //     console.log('length',selectedFonts.length );
  //     setActiveIndexes(newActiveIndexes);
  //   }
  // }, [dataType, logoStyles, selectedLayouts, selectedFonts, StyleReverseMapping, LayoutReverseMapping, FontReverseMapping]);
  const updateActiveIndexes = debounce((newActiveIndexes) => {
    setActiveIndexes(newActiveIndexes);
  }, 300);

  // Initial synchronization when the component mounts
  useEffect(() => {
    if (!initialSyncDone.current) {
      let newActiveIndexes = [];

      if (dataType === 'style' && logoStyles.length > 0) {
        newActiveIndexes = logoStyles.map(style => StyleReverseMapping[style]);
      } else if (dataType === 'layout' && selectedLayouts.length > 0) {
        newActiveIndexes = selectedLayouts.map(layout => LayoutReverseMapping[layout]);
      } else if (dataType === 'font' && selectedFonts.length > 0) {
        newActiveIndexes = selectedFonts.map(font => FontReverseMapping[font]);
      }

      if (newActiveIndexes.length > 0) {
        setActiveIndexes(newActiveIndexes);
      }
      
      initialSyncDone.current = true; // Mark the initial synchronization as done
    }
  }, [dataType, logoStyles, selectedLayouts, selectedFonts, setActiveIndexes]);

  


  const handleCardClick = (index) => {
    // Check if the index is already in the array of activeIndexes
    if (activeIndexes.includes(index)) {
      // If it is, remove it
      setActiveIndexes(activeIndexes.filter((item) => item !== index));
    } else {
      // If it's not, add it
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const handleMouseMove = (event) => {
    const carouselWidth = event.currentTarget.offsetWidth;
    const mouseX = event.clientX - event.currentTarget.getBoundingClientRect().left;

    if (mouseX < carouselWidth * 0.3 && !isNearEdge) {
      // Near the left side
      setCurrentIndex(0);
      setIsNearEdge(true); // Prevent repeated setting
    } else if (mouseX > carouselWidth * 0.7 && !isNearEdge) {
      // Near the right side
      setCurrentIndex(cardData.length - 3);
      setIsNearEdge(true); // Prevent repeated setting
    } else if (mouseX >= carouselWidth * 0.1 && mouseX <= carouselWidth * 0.9) {
      setIsNearEdge(false); // Reset when not near the edges
    }
  };

  useEffect(() => {
    console.log(`Active index changed: ${activeIndexes}`);
  }, [activeIndexes]);

  useEffect(() => {
    return () => clearInterval(intervalRef.current); // Clean up the interval on component unmount
  }, []);

  const handleMouseLeave = () => {
    clearInterval(intervalRef.current);
  };

  return (
    <>
      <div
        className={styles.sliderContainer}
        onMouseMove={handleMouseMove}
        // onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ overflow: 'hidden', position: 'relative' }}
      >
        <div
          className={styles.cardCarousel}
          style={{
            transform: `translateX(-${(currentIndex * 100) / cardData.length}%)`,
            transition: 'transform 1s ease-in-out'
          }}
        >
          {cardData?.map((card, index) => (
            <div className={`${styles.cardContainer} ${activeIndexes.includes(index) ? styles.active : ''}`} key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleCardClick(index)}
            >
              <div className={styles.content} style={{ height: height }}>
                <div className={`${styles.selectedIcon} ${activeIndexes.includes(index) ? styles.visible : ''}`}><img src={selected} /></div>
                <div className={`${styles.selectionIcon} ${hoveredIndex === index ? styles.visible : ''}`}

                >
                  <img src={selection} /></div>
                <div className={styles.imageContainer}>
                  <img src={card.image} alt={card.caption} />
                </div>
                <p className={styles.caption}>{card.caption}</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center h-100 " style={{ backgroundColor: "white", width: '274px', borderRadius: '0px 0px 9px 9px' }}>
                <p className={styles.description} style={{ paddingTop: paddingTop, fontFamily: fontFamily }}>{card.description}</p>
                <div className={styles.iconContainer} style={{ display: display }}>
                  {card.icons?.map((icon, idx) => (
                    <div className={styles.icon} key={idx}>
                      <img src={icon} alt={`Icon ${idx}`} style={{ width: card.width, height: card.height }} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

    </>
  );
}

export default CustomCardCarousel;





{/* <div className="card-container">
      <div className="content">
        <div className="image-container">
          <img src={initials} alt="Main" />
        </div>
        <p className="caption">Initials</p>
      </div>
      <div className="divider"></div>
      <p className="description">
        Used to represent brands by initials. Ideal for long names.
      </p>
      <div className="icon-container">
        <div className="icon">
          <img src={ibm} alt="IBM" />
        </div>
        <div className="icon">
          <img src={cnn} alt="CNN" />
        </div>
        <div className="icon">
          <img src={hbo} alt="HBO" />
        </div>
      </div>
    </div> */}
import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
       {/* Left navigation arrow */}
      <li
        className={classnames('', {
          disabled: currentPage === 1
        })}
        style={{padding:0}}
        onClick={onPrevious}
      >
          <button
        className={classnames('arrowButton', {
            disabled: currentPage === 1
            
          })}
          style={{marginRight:'1rem'}}
          disabled={currentPage === 1}
          >
  
        <ArrowLeftIcon className='arrowsvgButton'style={{fontSize:'2rem', fontWeight:'400', marginRight:'1rem'}}/>
      </button>

      </li>
      {paginationRange.map(pageNumber => {
         
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }
		
        // Render our Page Pills
        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
            
          >
            {pageNumber}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        className={classnames('', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
        style={{padding:0}}
      >
         <button
        className={classnames('arrowButton', {
            disabled: currentPage === lastPage
          })}
          disabled={currentPage === lastPage}
      >
        <ArrowRightIcon style={{fontSize:'2rem', fontWeight:'400'}}/>
      </button>
      </li>
    </ul>
  );
};

export default Pagination;
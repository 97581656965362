import React, { useEffect, useRef, useState } from "react";
import img from "../assets/8961416_4034724 1.png";
import axios from 'axios';
import "./Graphics.css";
import styles1 from "../pages/CustomDesignServices.module.css";
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { baseURL } from '../config/config';

const Graphics = () => {
  const [featuredSection, setFeaturedSection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nonFeaturedData, setnonFeaturedData] = useState([]);

  const buttonRowRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollSpeed = 20;
  const [activeStep, setActiveStep] = useState('Logo & Identity');
  const handleButtonClick = (step) => {
    setActiveStep(step);
  };


  useEffect(() => {
    const scrollContainer = buttonRowRef.current;
    let scrollIntervalId;

    const startScrolling = () => {
      scrollIntervalId = setInterval(() => {
        if (isScrolling === 'left') {
          scrollContainer.scrollLeft -= scrollSpeed;
        } else if (isScrolling === 'right') {
          scrollContainer.scrollLeft += scrollSpeed;
        }
      }, 50);
    };

    const stopScrolling = () => {
      clearInterval(scrollIntervalId);
    };

    if (isScrolling) {
      startScrolling();
    }

    return () => stopScrolling();
  }, [isScrolling, scrollSpeed]); // Added scrollSpeed to dependency array

  useEffect(() => {
    const fetchEntry = async () => {
      setFeaturedSection(null);
      let response;
      try {
        if (activeStep === 'Logo & Identity') {
          response = await axios.get(`${baseURL}/getFeatured/7`);
        }
        else if (activeStep === 'Web & App Design') {
          response = await axios.get(`${baseURL}/getFeatured/1`);
        }
        else if (activeStep === 'Business & Advertisement') {
          response = await axios.get(`${baseURL}/getFeatured/2`);
        }
        else if (activeStep === 'Clothing & merchandise') {
          response = await axios.get(`${baseURL}/getFeatured/3`);
        }
        else if (activeStep === 'Art & Illustration') {
          response = await axios.get(`${baseURL}/getFeatured/4`);
        }
        else if (activeStep === 'Packaging & label') {
          response = await axios.get(`${baseURL}/getFeatured/5`);
        }
        else if (activeStep === 'Book & magazine') {
          response = await axios.get(`${baseURL}/getFeatured/6`);
        }

        if (response) {
          setFeaturedSection(response.data);
          console.log('response dataaa', response.data);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        // setError(err.message);
        // setLoading(false);
      }
    };

    fetchEntry();
  }, [activeStep]);

  useEffect(() => {
    const fetchNonFeatured = async () => {
      setnonFeaturedData(null);
      let response;
      try {
        if (activeStep === 'Logo & Identity') {
          response = await axios.get(`${baseURL}/getNonFeatured/7`);
        }
        else if (activeStep === 'Web & App Design') {
          response = await axios.get(`${baseURL}/getNonFeatured/1`);
        }
        else if (activeStep === 'Business & Advertisement') {
          response = await axios.get(`${baseURL}/getNonFeatured/2`);
        }
        else if (activeStep === 'Clothing & merchandise') {
          response = await axios.get(`${baseURL}/getNonFeatured/3`);
        }
        else if (activeStep === 'Art & Illustration') {
          response = await axios.get(`${baseURL}/getNonFeatured/4`);
        }
        else if (activeStep === 'Packaging & label') {
          response = await axios.get(`${baseURL}/getNonFeatured/5`);
        }
        else if (activeStep === 'Book & magazine') {
          response = await axios.get(`${baseURL}/getNonFeatured/6`);
        }

        if (response) {
          setnonFeaturedData(response.data);
          console.log('response dataaa', response.data);
          // setLoading(false);
        }
      } catch (err) {
        console.log(err);
        // setError(err.message);
        // setLoading(false);
      }
    };

    fetchNonFeatured();
  }, [activeStep]);



  const handleMouseMove = (e) => {
    const { left, right } = e.currentTarget.getBoundingClientRect();
    const scrollAreaWidth = (right - left) * 0.1;
    if (e.clientX - left < scrollAreaWidth) {
      setIsScrolling('left');
    } else if (right - e.clientX < scrollAreaWidth) {
      setIsScrolling('right');
    } else {
      setIsScrolling(false);
    }
  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;


  return (
    <div className="container  px-3 px-lg-0 px-md-0" >
      <h1 className="graphic-heading">
        What do you need designed <span className="qMark">?</span>
      </h1>

      <div
        className="button-row"
        ref={buttonRowRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={() => setIsScrolling(false)}

      >

        <div className={`buttonNav ${activeStep === 'Logo & Identity' ? styles1.activeButton : ''}`} onClick={() => handleButtonClick('Logo & Identity')}>Logo & Identity</div>
        <div className={`buttonNav ${activeStep === 'Web & App Design' ? styles1.activeButton : ''}`} onClick={() => handleButtonClick('Web & App Design')}>Web & App Design</div>
        <div className={`buttonNav ${activeStep === 'Business & Advertisement' ? styles1.activeButton : ''}`} onClick={() => handleButtonClick('Business & Advertisement')}>Business & Advertisement</div>
        <div className={`buttonNav ${activeStep === 'Clothing & merchandise' ? styles1.activeButton : ''}`} onClick={() => handleButtonClick('Clothing & merchandise')}>Clothing & merchandise</div>
        <div className={`buttonNav ${activeStep === 'Art & Illustration' ? styles1.activeButton : ''}`} onClick={() => handleButtonClick('Art & Illustration')}>Art & Illustration</div>
        <div className={`buttonNav ${activeStep === 'Packaging & label' ? styles1.activeButton : ''}`} onClick={() => handleButtonClick('Packaging & label')}>Packaging & label</div>
        <div className={`buttonNav ${activeStep === 'Book & magazine' ? styles1.activeButton : ''}`} onClick={() => handleButtonClick('Book & magazine')}>Book & magazine</div>



      </div>

      {featuredSection &&
        <Row style={{paddingLeft:0, paddingRight:0, marginLeft:0, marginRight:0}}>
          <Col xs={12} md={6} lg={6} style={{paddingLeft:0, paddingRight:0, marginTop:'1rem'}}>
            <Link to={`/services/${featuredSection?.slug}`} style={{ textDecoration: 'none' }}>
              <div className=" mt-lg-5 mt-md-5 mt-sm-0">
                
                    <div className="position-relative main-container w-100">
                      <div className="d-flex flex-column">
                        <div className="pb-4">
                          <button className="most-popular-button">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_589_11463)">
                                <path
                                  d="M12.3795 4.81824L8.72247 4.47907L7.2698 1.10586C7.16808 0.869662 6.8332 0.869662 6.73151 1.10586L5.27885 4.47904L1.62186 4.81824C1.36578 4.84198 1.26231 5.16048 1.4555 5.3302L4.21471 7.75412L3.40722 11.337C3.35067 11.5878 3.62162 11.7847 3.84272 11.6534L7.00066 9.77825L10.1586 11.6534C10.3797 11.7847 10.6506 11.5878 10.5941 11.337L9.78658 7.75412L12.5458 5.3302C12.739 5.16048 12.6355 4.84198 12.3795 4.81824Z"
                                  fill="white"
                                  fill-opacity="0.85"
                                />
                                <path
                                  d="M7.79803 2.3325L7.26978 1.10586C7.16806 0.869662 6.83318 0.869662 6.73149 1.10586L5.27882 4.47904L1.62186 4.81824C1.36578 4.84198 1.26231 5.16048 1.4555 5.3302L4.21471 7.75412L3.40722 11.337C3.35067 11.5878 3.62162 11.7847 3.84272 11.6534L4.26302 11.4038C4.95206 6.94349 6.88915 3.66928 7.79803 2.3325Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_589_11463">
                                  <rect
                                    width="14"
                                    height="14"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>{" "}
                            Most popular
                          </button>
                        </div>
                        {featuredSection && (
                          <div className="d-flex flex-column content-container">
                            <div className="d-flex first-icon">

                              <div>
                                <img src={`${baseURL}/uploads/${featuredSection?.icon}`} alt={featuredSection?.icon_alt_text} width="40" height="40" />
                              </div>
                              <div className="text-container">
                                <span className="logo-txt">{featuredSection?.heading}</span>
                                <div className="pt-1">
                                  <span>From US$ {featuredSection?.price}</span>
                                  {/* <span className="discount-text">39% off</span> */}
                                </div>
                              </div>
                            </div>
                            <div>
                              {/* <p>
                      A logo plus digital and print essentials to kick-start
                      your brand
                    </p> */}
                              <p style={{ color: "black" }}>{featuredSection?.description}</p>
                              <ul className="benefits-list">
                                <li>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.6946 3.70502C11.9679 3.97839 11.9679 4.42161 11.6946 4.69498L6.09459 10.295C5.82122 10.5683 5.378 10.5683 5.10463 10.295L2.30463 7.49497C2.03127 7.22162 2.03127 6.77839 2.30463 6.50502C2.578 6.23166 3.02122 6.23166 3.29459 6.50502L5.59961 8.81006L10.7046 3.70502C10.978 3.43166 11.4212 3.43166 11.6946 3.70502Z"
                                        fill="#CB0064"
                                      />
                                    </svg>
                                  </span>
                                  {featuredSection?.usp1}
                                </li>
                                <li>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.6946 3.70502C11.9679 3.97839 11.9679 4.42161 11.6946 4.69498L6.09459 10.295C5.82122 10.5683 5.378 10.5683 5.10463 10.295L2.30463 7.49497C2.03127 7.22162 2.03127 6.77839 2.30463 6.50502C2.578 6.23166 3.02122 6.23166 3.29459 6.50502L5.59961 8.81006L10.7046 3.70502C10.978 3.43166 11.4212 3.43166 11.6946 3.70502Z"
                                        fill="#CB0064"
                                      />
                                    </svg>
                                  </span>
                                  {featuredSection?.usp2}
                                </li>
                                <li>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.6946 3.70502C11.9679 3.97839 11.9679 4.42161 11.6946 4.69498L6.09459 10.295C5.82122 10.5683 5.378 10.5683 5.10463 10.295L2.30463 7.49497C2.03127 7.22162 2.03127 6.77839 2.30463 6.50502C2.578 6.23166 3.02122 6.23166 3.29459 6.50502L5.59961 8.81006L10.7046 3.70502C10.978 3.43166 11.4212 3.43166 11.6946 3.70502Z"
                                        fill="#CB0064"
                                      />
                                    </svg>
                                  </span>
                                  {featuredSection?.usp3}
                                </li>
                                <li>
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.6946 3.70502C11.9679 3.97839 11.9679 4.42161 11.6946 4.69498L6.09459 10.295C5.82122 10.5683 5.378 10.5683 5.10463 10.295L2.30463 7.49497C2.03127 7.22162 2.03127 6.77839 2.30463 6.50502C2.578 6.23166 3.02122 6.23166 3.29459 6.50502L5.59961 8.81006L10.7046 3.70502C10.978 3.43166 11.4212 3.43166 11.6946 3.70502Z"
                                        fill="#CB0064"
                                      />
                                    </svg>
                                  </span>
                                  {featuredSection?.usp4}
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* right side  */}

              
            </Link>
          </Col>

          {nonFeaturedData && (
<>
            <Col xs={12} md={6} lg={6} className="mt-lg-5 mt-md-5 mt-sm-0 d-flex flex-row justify-content-start align-items-center ps-0 pe-0" >

              <Col xs={12} md={6} lg={6} style={{paddingLeft:0, paddingRight:0, marginTop:'1rem'}}>
                <Link to={`/services/${nonFeaturedData[0]?.slug}`} style={{ textDecoration: 'none' }}>
                  <div  style={{ marginBottom: 0 }}>


                    <div className="position-relative"
                      style={{
                        backgroundColor: '#fff',
                        width: '100%',
                        height: '306px',
                        border: '1px solid #f8f8f8',
                        padding: '20px',
                        boxSizing: 'border-box'
                      }}>
                      <div className="d-flex flex-column  pt-4">
                        <div className="d-flex flex-column content-container">
                          <div className="d-flex first-icon">
                            <div>
                              <img src={`${baseURL}/uploads/${nonFeaturedData[0]?.icon}`} alt={nonFeaturedData[0]?.icon_alt_text} width="40" height="40" />
                            </div>
                            <div className="text-container">
                              <span className="logo-txt">{nonFeaturedData[0]?.heading}</span>
                              <div className="pt-1">
                                <span>From US$ {nonFeaturedData[0]?.price}</span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <p style={{ color: "black" }}>{nonFeaturedData[0]?.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
              {nonFeaturedData[1] && (
              <Col  xs={12} md={6} lg={6}  className="d-none d-lg-flex d-md-flex " style={{paddingLeft:0, paddingRight:0,marginTop:'1rem'}}>

                <Link to={`/services/${nonFeaturedData[1]?.slug}`} style={{ textDecoration: 'none' }}>
                  <div  style={{ marginBottom: 0 }}>
                      <div className="position-relative" 
                      style={{
                        backgroundColor:'#fff', 
                        width:'100%', 
                        height:'306px',
                        border: '1px solid #f8f8f8',
                        padding:'20px',
                        boxSizing:'border-box'
                        }}
                         >
                        <div className="d-flex flex-column pt-4">
                          <div className="d-flex flex-column content-container">
                            <div className="d-flex first-icon">
                              <div>
                                <img src={`${baseURL}/uploads/${nonFeaturedData[1]?.icon}`} alt={nonFeaturedData[1]?.icon_alt_text} width="40" height="40" />
                              </div>
                              <div className="text-container">
                                <span className="logo-txt">{nonFeaturedData[1]?.heading}</span>
                                <div className="pt-1">
                                  <span>From US$ {nonFeaturedData[1]?.price}</span>
                                </div>
                              </div>
                            </div>
                            
                              <p style={{ color: "#4F4F4F", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal", wordWrap: "break-word", fontFamily:'Poppins', fontSize:'0.8125rem'}}>{nonFeaturedData[1]?.description}</p>
                            
                          </div>
                        </div>
                      </div>
                     </div>
                </Link>
              </Col>
              )}
            </Col>
            {nonFeaturedData[1] && (
             <Col  xs={12} md={6} lg={6}  className="d-flex d-lg-none d-md-none" style={{paddingLeft:0, paddingRight:0}}>

             <Link to={`/services/${nonFeaturedData[1]?.slug}`} style={{ textDecoration: 'none' }}>
               <div className="" style={{ marginBottom: 0 }}>
                   <div className="position-relative" 
                   style={{
                     backgroundColor:'#fff', 
                     width:'100%', 
                     height:'306px',
                     border: '1px solid #f8f8f8',
                     padding:'20px',
                     boxSizing:'border-box'
                     }}
                      >
                     <div className="d-flex flex-column pt-4">
                       <div className="d-flex flex-column content-container">
                         <div className="d-flex first-icon">
                           <div>
                             <img src={`${baseURL}/uploads/${nonFeaturedData[1]?.icon}`} alt={nonFeaturedData[1]?.icon_alt_text} width="40" height="40" />
                           </div>
                           <div className="text-container">
                             <span className="logo-txt">{nonFeaturedData[1]?.heading}</span>
                             <div className="pt-1">
                               <span>From US$ {nonFeaturedData[1]?.price}</span>
                             </div>
                           </div>
                         </div>
                         
                           <p style={{ color: "#4F4F4F", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal", wordWrap: "break-word", fontFamily:'Poppins', fontSize:'0.8125rem'}}>{nonFeaturedData[1]?.description}</p>
                         
                       </div>
                     </div>
                   </div>
                  </div>
             </Link>
           </Col>
            )}
           </>
          )}
        </Row>
      }
      <Row style={{paddingLeft:0, paddingRight:0, marginLeft:0, marginRight:0}}>
        {nonFeaturedData && nonFeaturedData.slice(2).map((nonFeatured, index) => {
          // Determine the appropriate column size based on the item index
          let colSize;
          if (index < 4 || index === 6) {
            colSize = 6; // First row: 3 items, each taking up 1/3 of the row
          }
    
          else {
            colSize = 3; // Subsequent rows: 4 items each, each taking up 1/4 of the row
          }

          return (
            <Col key={nonFeatured.slug} md={colSize} lg={colSize} style={{paddingLeft:0, paddingRight:0}}>
              <Link to={`/services/${nonFeatured?.slug}`} style={{ textDecoration: 'none' }}>
                <div className="" style={{ marginTop: 0, backgroundColor: 'Highlight' }}>
                  
                      <div className="position-relative main-container w-100" style={{ backgroundColor: '#fff' }}>
                        <div className="d-flex flex-column h-100  pt-4">
                          <div className="d-flex flex-column content-container h-100">
                            <div className="d-flex first-icon">
                              <div>
                                <img src={`${baseURL}/uploads/${nonFeatured.icon}`} alt={nonFeatured.icon_alt_text} width="40" height="40" />
                              </div>
                              <div className="text-container">
                                <span className="logo-txt">{nonFeatured.heading}</span>
                                <div className="pt-1">
                                  <span>From US$ {nonFeatured.price}</span>
                                  {/* <span className="discount-text">39% off</span> */}
                                </div>
                              </div>
                            </div>
                            
                              <p style={{ color: "#4F4F4F", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "normal", wordWrap: "break-word", fontFamily:'Poppins', fontSize:'0.8125rem'}}>{nonFeatured.description}</p>
                            
                          </div>
                        </div>
                      </div>
                    </div>   
              </Link>
            </Col>
          );
        })}
      </Row>

    </div>
  );
};


export default Graphics;

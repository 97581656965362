import React, { useState } from 'react';
// import './home.css';
import CustomCardCarousel from '../components/Card';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "../pages/Step1.module.css";
import FontStyle1 from "../assets/EP.png";
import FontStyle2 from "../assets/EP (1).png";
import FontStyle3 from "../assets/EP (2).png";
import FontStyle4 from "../assets/EP (3).png";
import FontStyle5 from "../assets/EP (4).png";
import FontIcon1 from "../assets/Times New Roman.png";
import FontIcon2 from "../assets/, Georgia.png";
import FontIcon3 from "../assets/Helvetica , Arial.png";
import FontIcon4 from "../assets/Brush Script , Lucida Handwriting.png";
import FontIcon5 from "../assets/Cooper Black , Papyrus.png";
import FontIcon6 from "../assets/Courier, Consolas.png";
import TimesNew from "../assets/TimesNew.svg";
import Helvetica from "../assets/Helvetica , Arial.svg";
import Brush from "../assets/Brush Script , Lucida Handwriting.svg";
import Cooper from "../assets/Cooper Black , Papyrus.svg";
import Curior from "../assets/Courier, Consolas.svg";


const cardData = [
  {
    image: FontStyle1,
    caption: "Serif",
    description: "Used by traditional industries like finance and law for reliability.",
    icons: [TimesNew],
    width: '14.625rem',
    height: '2rem',
  },
  {
    image: FontStyle2,
    caption: "Sans Serif",
    description: "Preferred by tech companies for a modern, clean look.",
    icons: [Helvetica],
    width: '8.625rem',
    height: '2rem',
  },
  {
    image: FontStyle3,
    caption: "Script",
    description: "Found in luxury brands and food products for elegance.",
    icons: [Brush],
    width: '16rem',
    height: '2rem',

  },
  {
    image: FontStyle4,
    caption: "Display",
    description: "For brands targeting younger audiences or seeking uniqueness.",
    icons: [Cooper],
    width: '13.625rem',
    height: '2rem',
  },
  {
    image: FontStyle5,
    caption: "Monospaced Fonts",
    description: "Associated with tech and coding for precision.",
    icons: [Curior],
    width: '9.625rem',
    height: '2rem',
  },

];


const BodySection = () => {
  return (
    <div>
       <div className={`col-12 col-md-6 ${styles.headingContainer}`} style={{ padding: 0 }}>
       <h1 className={`${styles.stepheading} text-md-left`}
        >
          Step 2/3
        </h1>
        <h2 className={`${styles.logoSelectionHeading} text-md-left`}>
          Select all of the Fonts styles you like
        </h2>
        <p className={`${styles.preferencestext} text-md-left`}>
          Your selections will help us understand your preferences.
        </p>
      </div>


      <CustomCardCarousel cardData={cardData} dataType='font'/>


    </div>
  );
};

export default BodySection;

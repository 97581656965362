// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.underline-input {
    border: none;
    border-bottom: 2px solid #ccc; /* Customize the color and thickness */
    border-radius: 0;
    outline: none;
    box-shadow: none;
    background-color: transparent; /* Make sure the background is transparent */
    position:relative;
    margin-top: 3rem;
    width: 27.65625rem;
}

.underline-input:focus {
    border-bottom: 1px solid #CB0064; /* Change the color on focus */
    outline: none;
    box-shadow: none;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/pages/Form.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,6BAA6B,EAAE,sCAAsC;IACrE,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,6BAA6B,EAAE,4CAA4C;IAC3E,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gCAAgC,EAAE,8BAA8B;IAChE,aAAa;IACb,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".underline-input {\n    border: none;\n    border-bottom: 2px solid #ccc; /* Customize the color and thickness */\n    border-radius: 0;\n    outline: none;\n    box-shadow: none;\n    background-color: transparent; /* Make sure the background is transparent */\n    position:relative;\n    margin-top: 3rem;\n    width: 27.65625rem;\n}\n\n.underline-input:focus {\n    border-bottom: 1px solid #CB0064; /* Change the color on focus */\n    outline: none;\n    box-shadow: none;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

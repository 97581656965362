import React, { useState, useRef } from "react";
// import './home.css';
import CustomCardCarousel from '../components/Card';
import styles from "../pages/Step1.module.css";
import Logo1 from "../assets//ExploreLogo1.png";
import Logo2 from "../assets//ExploreLogo2.png";
import Logo3 from "../assets//ExploreLogo3.png";
import Logo4 from "../assets//ExploreLogo4.png";
import Logo5 from "../assets//ExploreLogo5.png";
import { useNavigate } from 'react-router-dom';
import styles2 from "./card.module.css";
import styles1 from "../pages/CustomDesignServices.module.css";
import './MakeLogo.css';


const cardData = [
    {
        image: Logo2,
        caption: "Logo Design Services ",
        // imgWidth: '4.0625rem',
        // imgHeight: '3.0625rem'


    },
    {
        image: Logo3,
        caption: "Complete Brand Pack",

    },
    {
        image: Logo4,
        caption: "Website Logo",


    },
    {
        image: Logo1,
        caption: "Packaging & Labels",

    },
    {
        image: Logo5,
        caption: "Web & App Design",

    },

];

const BodySection = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef(null);

    const [isNearEdge, setIsNearEdge] = useState(false);
    const navigate = useNavigate();

    const handleMouseLeave = () => {
        clearInterval(intervalRef.current);
    };


    const handleMouseMove = (event) => {
        const carouselWidth = event.currentTarget.offsetWidth;
        const mouseX = event.clientX - event.currentTarget.getBoundingClientRect().left;

        if (mouseX < carouselWidth * 0.3 && !isNearEdge) {
            // Near the left side
            setCurrentIndex(0);
            setIsNearEdge(true); // Prevent repeated setting
        } else if (mouseX > carouselWidth * 0.7 && !isNearEdge) {
            // Near the right side
            setCurrentIndex(cardData.length - 3);
            setIsNearEdge(true); // Prevent repeated setting
        } else if (mouseX >= carouselWidth * 0.1 && mouseX <= carouselWidth * 0.9) {
            setIsNearEdge(false); // Reset when not near the edges
        }
    };

    return (

        <div className="whyChooseWrapper text-center d-flex flex-column justify-content-center align-items-center" >
            <h2 className="sectionHeading" style={{ marginBottom: '0.62rem', width: '29.8125rem' }}> { "Explore Our Other Graphic Design Services"}<span className="questionMark">.</span></h2>
            <p className="sectionDescription" style={{width: "46.8125rem"}}>
                {"From business cards to social media graphics, we offer a variety of graphic design services to meet all your branding needs. Check out our complete range of services below."}
            </p>

            
                <div
                    className="slider-container"
                    onMouseMove={handleMouseMove}
                    // onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ overflow: 'hidden', position: 'relative', marginTop:'1.62rem'}}
                >
                    <div
                        className="card-carousel"
                        style={{
                            transform: `translateX(-${(currentIndex * 100) / cardData.length}%)`,
                            transition: 'transform 0.5s ease-in-out',
                            display:'flex',
                            gap:'1.88rem',
                            transition: 'transform 1s ease-in-out',
                            width: "calc(231px* 5)"
                        }}
                    >
                        {cardData?.map((card, index) => (
                            <div className={styles1.cardContainer} key={index}
                                // onMouseEnter={() => setHoveredIndex(index)}
                                // onMouseLeave={() => setHoveredIndex(null)}
                                onClick={() => handleCardClick(index)}
                            >
                                <div className={styles1.exploreSectionCard} >

                                    <div className={styles1.exploreSectionCardImg}>
                                        <img src={card.image} alt={card.caption}
                                        style={{ maxWidth: "100%", height: '100%', objectFit:'cover', borderRadius:'0.625rem'}}
                                        />
                                    </div>
                                    <p className={styles1.exploreSectionCaption}>{card.caption}</p>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>

      
        </div>

    );
};

export default BodySection;

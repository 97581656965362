import React, { useState, useEffect } from 'react';
import "./MakeLogo.css";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import automatic from "../assets/automatic  and transportation.png";
import badge from "../assets/badge.png";
import youcompanyname from "../assets/youcompanyname.png";
import star from "../assets/star.png";
import automaticCar from "../assets/automatic.png";
import companyName from "../assets/companyName.png";
import btn from "../assets/Ellipse 11.svg";
import btn1 from "../assets/Ellipse 12.svg";
import logo1 from "../assets/coffee-1.jpg";
import logo2 from "../assets/coffee-2.jpg";
import logo3 from "../assets/coffee-3.jpg";
import logo4 from "../assets/real-estate-1.png";
import logo5 from "../assets/real-estate-2.png";
import logo6 from "../assets/real-estate-3.png";
import logo7 from "../assets/food-1.jpg";
import logo8 from "../assets/food-2.jpg";
import logo9 from "../assets/food-3.jpg";
import logo10 from "../assets/medical-1.jpg";
import logo11 from "../assets/medical-2.jpg";
import logo12 from "../assets/medical-3.jpg";
import logo13 from "../assets/travel-1.jpg";
import logo14 from "../assets/travel-2.jpg";
import logo15 from "../assets/travel-3.jpg";
import styles1 from "../pages/CustomDesignServices.module.css";
import ButtonWithBackground from './ButtonWithBackground';
import Icon, { KeyIcon, MedicalIcon, FoodIcon, CoffeeIcon } from './Icon';
import './ButtonWithBackground.css';
import styles from "./card.module.css";
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../config/config';

const cards = [
    { image: logo1, caption: "Abstract Logos", bg: "#D0E6D1", width: "10.6875rem", height: "8.3125rem", category: 'Coffee' },
    { image: logo2, caption: "Emblem Logos", bg: "#0F4258", width: "11.125rem", height: "6.8125rem", category: 'Coffee' },
    { image: logo3, caption: "Lettermark Logos", bg: "#D9D9C0", width: "11.05294rem", height: "6.8125rem", category: 'Coffee' },
    { image: logo4, caption: "Monogram Logos", bg: "#CFF0FF", width: "8.27806rem", height: "6.875rem", category: 'Real State' },
    { image: logo5, caption: "Pictorial Logos", bg: "#CDDFFB", width: "9.9375rem", height: "5.375rem", category: 'Real State' },
    { image: logo6, caption: "Combination marks Logos", bg: "#E6D9D0", width: "8.9375rem", height: "7rem", category: 'Real State' },
    { image: logo7, caption: "Abstract Logos", bg: "#D0E6D1", width: "10.6875rem", height: "8.3125rem", category: 'Real State' },
    { image: logo8, caption: "Emblem Logos", bg: "#0F4258", width: "11.125rem", height: "6.8125rem", category: 'Real State' },
    { image: logo9, caption: "Lettermark Logos", bg: "#D9D9C0", width: "11.05294rem", height: "6.8125rem", category: 'Real State' },

    { image: logo7, caption: "Abstract Logos", bg: "#D0E6D1", width: "10.6875rem", height: "8.3125rem", category: 'Food' },
    { image: logo8, caption: "Emblem Logos", bg: "#0F4258", width: "11.125rem", height: "6.8125rem", category: 'Food' },
    { image: logo9, caption: "Lettermark Logos", bg: "#D9D9C0", width: "11.05294rem", height: "6.8125rem", category: 'Food' },
    { image: logo10, caption: "Monogram Logos", bg: "#CFF0FF", width: "8.27806rem", height: "6.875rem", category: 'Medical' },
    { image: logo11, caption: "Pictorial Logos", bg: "#CDDFFB", width: "9.9375rem", height: "5.375rem", category: 'Medical' },
    { image: logo12, caption: "Combination marks Logos", bg: "#E6D9D0", width: "8.9375rem", height: "7rem", category: 'Medical' },

    { image: logo13, caption: "Abstract Logos", bg: "#D0E6D1", width: "10.6875rem", height: "8.3125rem", category: 'Travel' },
    { image: logo14, caption: "Emblem Logos", bg: "#0F4258", width: "11.125rem", height: "6.8125rem", category: 'Travel' },
    { image: logo15, caption: "Lettermark Logos", bg: "#D9D9C0", width: "11.05294rem", height: "6.8125rem", category: 'Travel' },


];




const LogoCards = ({ browseSectionData }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [smallCurrentIndex, setSmallCurrentIndex] = useState(0);
    const logosPerPage = 6;
    // const totalPages = Math.ceil(cards.length / logosPerPage);
    const [direction, setDirection] = useState('');
    const [activeStep, setActiveStep] = useState('Real State');
    const [filteredCards, setFilteredCards] = useState(cards);
    const [totalPages, setTotalPages] = useState(0);
    const [data, setData] = useState({});
    const [nestedData, setNestedData] = useState([]);
    const navigate = useNavigate();

    // const { disableScroll, enableScroll } = usePreventBodyScroll();

    useEffect(() => {
        if (browseSectionData){
            setData(browseSectionData);
        setNestedData(browseSectionData?.steps);
        setActiveStep(browseSectionData?.steps[0]?.category_name)}
        console.log('stepsSectionData', browseSectionData);
    }, [browseSectionData]);

    function onWheel(apiObj, ev) {
        const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isThouchpad) {
            ev.stopPropagation();
            return;
        }

        if (ev.deltaY < 0) {
            apiObj.scrollNext();
        } else if (ev.deltaY > 0) {
            apiObj.scrollPrev();
        }
    }


    const handleSmallBack = () => {
        setSmallCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleSmallNext = () => {
        setSmallCurrentIndex((prevIndex) => Math.min(prevIndex + 1, filteredCards.length - 1));
    };

    const handleButtonClick = (step) => {
        setActiveStep(step);
    };

    const handleNext = () => {
        if (currentIndex < totalPages - 1) {
            setDirection('next');
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleBack = () => {
        if (currentIndex > 0) {
            setDirection('prev');
            setCurrentIndex(currentIndex - 1);
        }
    };

    const getLogosToDisplay = (cards, index) => {
        return cards.slice(index * logosPerPage, (index + 1) * logosPerPage);
    };

    useEffect(() => {
        // Filter cards based on the active step
        const updatedFilteredCards = nestedData.filter(card => card.category_name === activeStep);
        setFilteredCards(updatedFilteredCards);
        setTotalPages(Math.ceil(updatedFilteredCards.length / logosPerPage));
    }, [activeStep, cards]);

    return (
        <div className={`${styles1.LogosWrapper} logosWrapper card-section text-center d-flex align-items-center flex-column`} style={{ paddingTop: '5rem', paddingBottom: "5rem" }}>
            <h2 className="browseSectionHeading">
                {data?.heading || 'Browse Our Custom Logo Designs to Experience Our Expertise'}
                <span className="dotStyle">.</span>
            </h2>

            <div className={styles1.stepsButtonsContainer} style={{ marginBottom: '2.63rem' }}>
                {/* {['Real State', 'Travel', 'Medical', 'Food', 'Coffee'].map((step) => ( */}
                {[...new Set(nestedData.map(step => step.category_name))].map((uniqueCategoryName, index) => (
                    <React.Fragment key={index}>
                        <button
                            className={`${styles1.stepsWhiteButton} ${styles1.stepsButton} ${activeStep === uniqueCategoryName ? styles1.activeButton : ''}`}
                            onClick={() => handleButtonClick(uniqueCategoryName)}
                            style={{ fontSize: '1.0625rem' }}
                        >
                            {uniqueCategoryName}
                        </button>
                    </React.Fragment>
                ))}

            </div>

            {/* Large screen layout */}
            <div className="card-container-large-screen flex-row d-none d-lg-block">
                <div className={`carousel ${direction}`} >
                    <div className="carousel-inner" >
                        {Array.from({ length: totalPages }).map((_, pageIndex) => (
                            <div key={pageIndex} className={`carousel-item ${pageIndex === currentIndex ? 'active' : ''}`}>
                                <div className="row customTransition" >
                                    {getLogosToDisplay(filteredCards, pageIndex).map((card, index) => (
                                        <div key={index} className="col-md-4" style={{ marginBottom: '1.38rem' }}>
                                            <div className="card custom-card">
                                                <div className="image-container"
                                                // style={{ backgroundColor: card.bg }}
                                                >
                                                    <img
                                                        src={`${baseURL}/uploads/${card.icon_path}`}
                                                        alt={card.icon_alt_text} />
                                                </div>
                                                {/* {cards.map((obj, index) => (
                                                <div className="card-body text-left">
                                                    <p className="card-text-cap customCaption">{obj.caption}</p>
                                                </div>
                                                   ))} */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="slider-controls d-flex justify-content-center mt-3">
                    <button
                        className={`slider-button ${currentIndex === 0 ? "active" : ""} ${totalPages == 0 ? "d-none" : ""
                            }`}
                        onClick={handleBack}
                        disabled={currentIndex === 0}
                        name='swapButton1'
                    ></button>
                    <button
                        className={`slider-button ${currentIndex === totalPages - 1 && totalPages > 1 ? "active" : ""
                            } ${totalPages == 0 ? "d-none" : ""
                            }`}
                        onClick={handleNext}
                        disabled={currentIndex === totalPages - 1}
                        name='swapButton2'
                    ></button>
                </div>
            </div>


            {/* Small screen layout */}
            <div className={`${styles1.stepsButtonsContainer} d-lg-none d-md-flex d-flex`}>
                {filteredCards.map((card, index) => (
                    <div key={index} className={`removePadding ${styles1.MinWidth}`}>
                        <div className="card custom-card">
                            <div className="image-container" style={{ backgroundColor: card.bg }}>
                                <img
                                    src={`${baseURL}/uploads/${card.icon_path}`}
                                    alt={card.icon_alt_text}
                                    className="card-img-top" />
                            </div>
                            {/* {cards.map((obj, index) => (
                            <div className="card-body text-left">
                                <p className="card-text-cap custom-caption">{obj.caption}</p>
                            </div>
                                ))} */}
                        </div>
                    </div>
                ))}
            </div>


        </div>
    );
};

export default LogoCards;

import React, { useEffect, useState } from "react";
import "./MakeLogo.css";
import './ButtonWithBackground.css';
import styles from "../pages/CustomDesignServices.module.css";
import styles1 from "../pages/Homepage.module.css";
import { useNavigate } from "react-router-dom";

const OrderSection = ({ctaSectionData}) => {
const [data, setData] = useState({});
const navigate = useNavigate();

useEffect(()=>{
if(ctaSectionData){
    setData(ctaSectionData[0])
}
}, [ctaSectionData])

    return (
        <div className={styles.OrderSectionWrapper}>
            <h2 className={styles.orderText}>
        { data? data.heading:`Let's Design Your Unique Logo Together! `}
            </h2>

            <button className={[styles1.masterPrimaryButton, styles1.buttonText1].join(' ')} onClick={() => navigate(data?.button_link || `/`)} style={{width: '9.3125rem', height: '2.625rem', padding:0, marginTop:'1.37rem', border: '1px solid #FFF'}}>
            <span className={styles1.buttonText} style={{fontSize:'0.875rem'}}>{ data? data.button_text:`Place An Order`}</span>
          </button>
        </div>
    );
};

export default OrderSection;

import React, {useState, useEffect}from "react";
import './MakeLogo.css';
import { baseURL } from '../config/config';


const WhyChoseUs = ({firstRowData, secondRowData, sectionHeading, sectionPara,marginTop,marginBottom}) => {
  const [dataFirst, setDataFirst] = useState([]);
  const [dataSecond, setDataSecond] = useState([]);



  useEffect(() => {
    if (firstRowData) {
      setDataFirst(firstRowData);
      setDataSecond(secondRowData);
    
    }
    console.log('firstRowData,,,,,,,,', firstRowData);
  }, [firstRowData]);

  return (
    <div className="whyChooseWrapper text-center" style={{paddingTop:marginTop, paddingBottom:marginBottom}}>
      <h2 className="sectionHeading" style={{ marginBottom: '1rem' }}> { sectionHeading ? sectionHeading : "Why Choose TheLogoMaker.AI"}<span className="questionMark">?</span></h2>
      <p className="sectionDescription">
      { sectionPara ? sectionPara : "Explore the practical benefits of using our online logo maker to design a professional logo for your brand." }
      </p>
      <div className="cardWrapper " >
        {dataFirst?.map((card, index) => (
          <div
            key={index}
            className={`colCont `}
          >
            <div className="why-card ">
              <img
                 src={card.icon ? `${baseURL}/uploads/${card.icon}` : card.logo}
                className="why-card-img"
                alt={card.icon_alt_text || card.heading }
                width="auto"
                height="auto"
              />
              <h3 className="cardTitle">{card.heading}</h3>
              <p className="cardText">{card.subheading}</p>
            </div>
          </div>
        ))}
        </div>
        <div className="cardWrapperSecond " >
        {dataSecond?.map((card, index) => (
          <div
            key={index}
            className={`colCont`}
          >
            <div className="why-card ">
              <img
                 src={card.icon ? `${baseURL}/uploads/${card.icon}` : card.logo}
                className="why-card-img"
                alt={card.icon_alt_text || card.heading}
                width="auto"
                height="auto"
              />
              <h5 className="cardTitle">{card.heading}</h5>
              <p className="cardText">{card.subheading}</p>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default WhyChoseUs;

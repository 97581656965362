import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import "./CustomLogoMaker.css";
import plus from '../assets/plus.svg';
import minus from '../assets/Minus.svg';

const FAQSection = ({ paddingTop, paddingBottom, isHighlighted, serviceFont, faqSectionData }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [data, setData] = useState(null);
  const [nestedData, setNestedData] = useState(null);

  useEffect(() => {
    if (faqSectionData){
      setData(faqSectionData);
    setNestedData(faqSectionData.faqs);}
    console.log('faqSectionData', faqSectionData);
  }, [faqSectionData]);

   const toggleCollapse = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqs = [
    {
      question: "What file formats are available for logo download?",
      answer:
        "Yes, you retain full ownership rights to the logo you create using our tool. It's exclusively yours to use as you see fit for your business or personal endeavors.",
    },
    {
      question: "Can I add a slogan to my logo?",
      answer: "Yes, you can add a slogan to your logo using our tool.",
    },
    {
      question: "What is the standard size for a logo?",
      answer: "The standard size for a logo is 1024x1024 pixels.",
    },
    {
      question: "Do I own the rights to my logo?",
      answer: "Yes, you own the rights to your logo.",
    },
    {
      question: "Can I use TheLogoMaker.ai to create logos for clients?",
      answer: "Yes, you can use TheLogoMaker.ai to create logos for clients.",
    },
    {
      question: "Is my logo design unique, or can others use a similar design?",
      answer:
        "Your logo design is unique to you, but others can create similar designs using our tool.",
    },
  ];

  return (
    <div className="FAQWraper" style={{ paddingTop: paddingTop, paddingBottom: paddingBottom, paddingLeft: '3rem', paddingRight: '3rem' }}>
    <div className="text-center mb-5">
      <h2 className="sectionHeading">
        {data?data.heading :'Frequently Asked Questions'}
        <span className="dotStyle">.</span>
      </h2>
    </div>
    <div className="row">
      {(nestedData ? nestedData : faqs).map((faq, index) => (
        <div key={index} className="col-12 ">
          <div
            className={openIndex === index ? 'paddingOpen' : 'paddingClose'}
            style={{
              backgroundColor: openIndex === index && isHighlighted
                ? "#FFFFFF"
                : openIndex === index
                  ? "#F8F8F8"
                  : "transparent",
              borderRadius: "5px",
              borderBottom: openIndex === index ? "1px solid transparent" : "1px solid #F0EFEF",
              borderImageSource: openIndex === index
                ? "linear-gradient(349.51deg, #CB0064 -6.45%, #9300B8 119.09%)"
                : "none",
              borderImageSlice: openIndex === index ? 1 : 0,
              // padding: openIndex === index ? '22px 46px 23px 46px' : '29px 46px 17px 46px',
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center"
              onClick={() => toggleCollapse(index)}
              style={{ cursor: "pointer" }}
            >
              <h3
                style={{
                  color: openIndex === index ? "#CB0064" : "#141414",
                  fontFamily: openIndex === index ? `Gilroy-SemiBold, sans-serif` : "",
                  fontSize: openIndex === index && serviceFont
                  ?serviceFont 
                  : openIndex === index?
                  '1.125rem'
                  :""
                 
                }}
                className="FAQQuestion"
              >
                {faq.question}
              </h3>
              <span
                className="gradient-text"
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "24px",
                  borderRadius: "50%",
                }}
              >
                {openIndex === index ? <img src={minus} alt="Close question" style={{width:'1.5rem', height:'1.5rem'}}/> :<img src={plus} alt="Open question" style={{width:'1.5rem', height:'1.5rem'}}/>}
              </span>
            </div>
            <Collapse in={openIndex === index}>
              <div
              className=""
                style={{
                  marginTop: "10px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#141414",
                }}
              >
                {faq.answer}
              </div>
            </Collapse>
          </div>
        </div>
      ))}
    </div>
  </div>
);
};
export default FAQSection;

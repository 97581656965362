

import React, { useState } from "react";
import "./MakeLogo.css";

import automatic from "../assets/automatic  and transportation.png";
import badge from "../assets/badge.png";
import youcompanyname from "../assets/youcompanyname.png";
import star from "../assets/star.png";
import automaticCar from "../assets/automatic.png";
import companyName from "../assets/companyName.png";
import btn from "../assets/Ellipse 11.svg";
import btn1 from "../assets/Ellipse 12.svg";
import logo1 from "../assets/Themed Logos-01.jpg";
import logo2 from "../assets/Themed Logos-02.jpg";
import logo3 from "../assets/Themed Logos-03.jpg";
import logo4 from "../assets/Themed Logos-04.jpg";
import logo5 from "../assets/Themed Logos-05.jpg";
import logo6 from "../assets/Themed Logos-06.jpg";
import logo7 from "../assets/Themed Logos-07.jpg";
import logo8 from "../assets/Themed Logos-08.jpg";
import logo9 from "../assets/Themed Logos-09.jpg";
import logo10 from "../assets/Themed Logos-10.jpg";
import logo11 from "../assets/Themed Logos-11.jpg";
import logo12 from "../assets/Themed Logos-12.jpg";
import styles1 from "../pages/CustomDesignServices.module.css";
const cards = [
  { image: logo1, caption: "Abstract Logos", bg: "#D0E6D1", width: "10.6875rem", height: "8.3125rem" },
  { image: logo5, caption: "Emblem Logos", bg: "#0F4258", width: "11.125rem", height: "6.8125rem" },
  { image: logo2, caption: "Lettermark Logos", bg: "#D9D9C0", width: "11.05294rem", height: "6.8125rem" },
  { image: logo6, caption: "Monogram Logos", bg: "#CFF0FF", width: "8.27806rem", height: "6.875rem" },
  { image: logo7, caption: "Pictorial Logos", bg: "#CDDFFB", width: "9.9375rem", height: "5.375rem" },
  { image: logo4, caption: "Combination marks Logos", bg: "#E6D9D0", width: "8.9375rem", height: "7rem" },

  { image: logo12, caption: "Abstract Logos", bg: "#D0E6D1", width: "10.6875rem", height: "8.3125rem" },
  { image: logo9, caption: "Emblem Logos", bg: "#0F4258", width: "11.125rem", height: "6.8125rem" },
  { image: logo8, caption: "Lettermark Logos", bg: "#D9D9C0", width: "11.05294rem", height: "6.8125rem" },
  { image: logo3, caption: "Monogram Logos", bg: "#CFF0FF", width: "8.27806rem", height: "6.875rem" },
  { image: logo10, caption: "Pictorial Logos", bg: "#CDDFFB", width: "9.9375rem", height: "5.375rem" },
  { image: logo11, caption: "Combination marks Logos", bg: "#E6D9D0", width: "8.9375rem", height: "7rem" },
];
const LogoCards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [smallCurrentIndex, setSmallCurrentIndex] = useState(0);
  const logosPerPage = 6;
  const totalPages = Math.ceil(cards.length / logosPerPage);
  const [direction, setDirection] = useState('');

  const handleNext = () => {
    if (currentIndex < totalPages - 1) {
      setDirection('next');
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setDirection('prev');
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleSmallBack = () => {
    setSmallCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSmallNext = () => {
    setSmallCurrentIndex((prevIndex) => Math.min(prevIndex + 1, cards.length - 1));
  };

  const getLogosToDisplay = (index) => {
    return cards.slice(index * logosPerPage, (index + 1) * logosPerPage);
  };

  return (
    <div className="logosHomeWrapper card-section text-center col-xs-12  ">
      <h2 className="sectionHeading">
        Explore Some of Our Logo Creations
        <span className="dotStyle">.</span>
      </h2>

      {/* Large screen layout */}
      <div className="card-container-large-screen flex-row d-none d-lg-block">
        <div className={`carousel-${direction}`}>
          <div className="carousel-inner">
            {Array.from({ length: totalPages }).map((_, pageIndex) => (
              <div
                key={pageIndex}
                className={`carousel-item ${pageIndex === currentIndex ? 'active' : ''}`}
                style={{
                  transition: 'transform 0.6s ease-in-out',
                  transform: pageIndex === currentIndex ? 'none' : `translateX(${100 * (pageIndex - currentIndex)}%)`,
                  opacity: pageIndex === currentIndex ? 1 : 0,
                  visibility: pageIndex === currentIndex ? 'visible' : 'hidden',
                }}
              >
                <div className="row">
                  {getLogosToDisplay(pageIndex).map((card, index) => (
                    <div key={index} className="col-md-4" style={{ marginBottom: '1.38rem' }}>
                      <div className="card custom-card">
                        <div className="image-container"
                      
                        >
                          <img src={card.image} alt={card.caption} />
                        </div>
                        <div className="card-body text-left">
                          <p className="card-text-cap customCaption">{card.caption}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="slider-controls d-flex justify-content-center mt-3">
          <button
            className={`slider-button ${currentIndex === 0 ? "active" : ""}`}
            onClick={handleBack}
            disabled={currentIndex === 0}
            name='swapButton1'
          ></button>
          <button
            className={`slider-button ${currentIndex === totalPages - 1 ? "active" : ""
              }`}
            onClick={handleNext}
            disabled={currentIndex === totalPages - 1}
            name='swapButton2'
          ></button>
        </div>
      </div>

      {/* Small screen layout */}
      <div className={`${styles1.stepsButtonsContainer} d-lg-none d-md-flex d-flex` }>
            {cards.map((card, index) => (
           
                <div className="card custom-card">
                  <div
                    className="image-container"
             
                  >
                    <img
                      src={card.image}
                      alt={card.caption}
                      className="card-img-top"
                    />
                  </div>
                  <div className="card-body text-left">
                    <p className="card-text-cap custom-caption">
                      {card.caption}
                    </p>
                  </div>
                </div>
              
            ))}
          </div>
    </div>
  );
};

export default LogoCards;

import React, { useState } from 'react';
import SvgBackground from './SVGButtonBg';
import Icon from './Icon';
import './ButtonWithBackground.css';

const ButtonWithBackground = () => {
  const [isActive, setIsActive] = useState(false);

  const handleMouseDown = () => {
    setIsActive(true);
  };

  const handleMouseUp = () => {
    setIsActive(false);
  };

  return (
    <div
      className={`button-container ${isActive ? 'buttonActive' : ''}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}  // To handle the case when mouse leaves while holding the button
    >
      {/* <SvgBackground isActive={isActive} /> */}
      <div className="icon-container">
        <Icon  style={{width:'2.5rem', height:'2.5rem'}}/>
      </div>
    </div>
  );
};

export default ButtonWithBackground;

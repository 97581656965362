import React, { useState, useEffect, useRef } from 'react';
import './CustomDropdown.css';
import Dropdown from '../assets/Dropdown.svg'
import Line from '../assets/Line.svg'
import { grey } from '@mui/material/colors';

const CustomDropdown = ({ options, placeholder, onChange  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option.value);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
 
      <div className="customSelect" onClick={handleToggle} ref={dropdownRef}>
        <div className={`custom-select-trigger ${isOpen ? 'open' : ''} ${selectedOption ? 'blackColor':'greyColor'}`}>
          {selectedOption ? selectedOption.label : placeholder}
          <div>
          <img src={Dropdown} alt="dropdown-icon" className={`icon1 ${isOpen ? 'd-none' : ''}`}/>
          <img src={Line} className={`icon2 ${isOpen ? 'd-block' : ''}`}/>
          </div>
          </div>
        {isOpen && (
          <div className={`custom-options ${isOpen ? 'open' : ''}`}>
            {options.map((option) => (
              <span
                key={option.value}
                className="custom-option"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </span>
            ))}
          </div>
        )}
      
      </div>
   
  );
};

export default CustomDropdown;

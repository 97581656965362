import React from 'react';

const SvgBackground = ({ text }) => (
  <div style={{
    width: '58px',
    height: '20px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #CB0064 21.74%, #9300B8 100%)',
    // clipPath: 'polygon(14.81% 0%, 100% 0%, 100% 100%, 14.81% 100%, 0% 50%)'
  }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="93" height="20" viewBox="0 0 58 20" fill="none" style={{ position: 'absolute', top: 0, left: 0 }}>
      <path d="M8.59177 0H58V20H8.63775C7.65053 20 6.7265 19.5143 6.1667 18.7012L1.20169 11.4891C0.481361 10.4428 0.498118 9.05622 1.24352 8.0276L6.16255 1.23963C6.72682 0.46097 7.63015 0 8.59177 0Z" fill="url(#paint0_linear_287_1144)"/>
      <defs>
        <linearGradient id="paint0_linear_287_1144" x1="12.6087" y1="10" x2="58" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CB0064"/>
          <stop offset="1" stopColor="#9300B8"/>
        </linearGradient>
      </defs>
    </svg>
    <span 
    style={{ 
    position: 'relative', 
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal'
    }}>{text}</span>
  </div>
);

export default SvgBackground;

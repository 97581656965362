import React from "react";
import pen from "../assets/BusinessIcon1.svg";
import card from "../assets/BusinessIcon2.svg";
import brand from "../assets/BusinessIcon3.svg";
import stationary from "../assets/BusinessIcon4.svg";
import servicesBrand from "../assets/BusinessIcon5.svg";
import "./CustomLogoMaker.css";

const services = [
  {
    logo: pen,
    caption: "Logos",
  },
  {
    logo: card,
    caption: "Business Card",
  },
  {
    logo: brand,
    caption: "Brand Guide",
  },
  {
    logo: stationary,
    caption: "Stationary",
  },
  {
    logo: servicesBrand,
    caption: "Service Brand Pack",
  },
];

const MyServices = () => {
  return (
    <div style={{ paddingBottom: "4.37rem" }} className="FAQWraper  pt-5">
      <div className="container text-center">
        <h2 className="sectionHeading">
          Grow Your Business With These Services By Your Side<span className="dotStyle">!</span>
        </h2>
        <div className="row justify-content-center align-items-center mt-4 responsiveGap" style={{gap:'2rem'}}>
          {services.map((service, index) => (
            <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-2 d-flex flex-column align-items-center service-card " style={{ padding: "10px", gap:'2.12rem' }}>
              <div>
                <img src={service.logo} alt={service.caption} className="card-img-top" style={{ marginTop: "10px", maxHeight: "60px" }} />
              </div>
              <div className="card-body text-center">
                <p className="card-text" style={{ fontFamily: "Poppins", fontSize: "17px", lineHeight: "24px", fontWeight: "500", color: "#000" }}>
                  {service.caption}
                </p>
              </div>
            </div>
          ))}
        </div>
        <button className="custom-button">View All Services</button>
      </div>
    </div>
  );
};

export default MyServices;

import styles from "../pages/Homepage.module.css";
import styles1 from "../pages/CustomDesignServices.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Animation from "../assets/Component 5.png";
import Animation2 from "../assets/Component4.png";
import bullet from "../assets/bullet.svg";
import Info from "../assets/info 1.svg";
import TopImg from "../assets/ServicePageHero.png";
import { Carousel } from "react-bootstrap";
import { Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomDropdown from "../components/CustomDropdown";
import { useEffect, useState } from "react";
import { baseURL } from '../config/config';

const options = [
  { value: "Automotives and Transportation", label: "Automotives and Transportation" },
  { value: "Business & Consulting", label: "Business & Consulting" },
  { value: "Food, Beverage, Restaurant", label: "Food, Beverage, Restaurant" },
  { value: "Travel & Holidays", label: "Travel & Holidays" },
  { value: "IT & Science", label: "IT & Science" },
  { value: "Marketing", label: "Marketing" },
];

const Frame5 = ({ heroSectionData }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [singleServiceDetail, setSingleServiceDetail] = useState(null);

  useEffect(() => {
    if (heroSectionData)
      setSingleServiceDetail(heroSectionData)
    console.log('heroSectionData', heroSectionData, 'singleServiceDetail',singleServiceDetail)
  }, [heroSectionData]);
  


  return (
    <div className={styles.imagePlaceholderParent}>
      <Row
        className={`${styles.wrappingContainer} ${styles1.heroGap}`}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingTop: "4.52rem",
          gap: "9rem",
          paddingBottom: "7.06rem",
        }}
      >
        <div
          className={styles.heroLeftContainer}
          style={{
            paddingLeft: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            width: "29.1875rem",
          }}
        >
          <div className={styles.createAProfessionalContainer}>
            <h1 className={styles.createAProfessionalLogoIn}>
              {singleServiceDetail?.heading || "Custom Logo Design Service"}
              <span className={styles.span}>.</span>
            </h1>
          </div>
          <p className={styles1.paragraphSec1}>
            {singleServiceDetail?.subHeading || "Delivering custom logos that capture your brand’s essence and uniqueness"}
          </p>

          <div>
            <ul className={styles1.customList}>
              <li>
                <p className={styles1.bulletHeadingSect1}>What you get</p>
              </li>
              <li>
                <img src={bullet} alt="Tick mark bullet" className={styles1.bulletIcon} />
                <p className={styles1.bulletTextSect1}>
                  {singleServiceDetail?.feature1 || ""}
                </p>
              </li>
              <li>
                <img src={bullet} alt="Tick mark bullet" className={styles1.bulletIcon} />
                <p className={styles1.bulletTextSect1}>
                  {singleServiceDetail?.feature2 || ""}
                </p>
              </li>
              <li>
                <img src={bullet} alt="Tick mark bullet" className={styles1.bulletIcon} />
                <p className={styles1.bulletTextSect1}>
                  {singleServiceDetail?.feature3 || ""}
                </p>
              </li>
              <li>
                <img src={bullet} alt="Tick mark bullet" className={styles1.bulletIcon} />
                <p className={styles1.bulletTextSect1}>
                  {singleServiceDetail?.feature4 || ""}
                </p>
              </li>
            </ul>
          </div>
          <div style={{ marginTop: "1.38rem" }}>
            <p className={styles1.bulletTextSect1}>
              Starting From{" "}
              <span className={styles1.pricingStyle}>
                ${singleServiceDetail?.startingFrom || ""}
              </span>
            </p>
            <div className="d-flex  align-items-center get-started-container">
              <button className={`${styles1.getStartedBtn}`} onClick={() => navigate(singleServiceDetail?.ctaButtonLink || '/')}>
              {singleServiceDetail?.ctaButtonText || 'Get Started'}
              </button>
            </div>
          </div>
        </div>

        <div className={styles1.heroRightContainer} style={{ paddingLeft: 0, flex: 1 }}>
          <img
            src={`${baseURL}/uploads/${singleServiceDetail?.bannerImage || ""}`}
            alt= {singleServiceDetail?.altText || "stationary items placed creatively"}
            className={`${styles1.topImage} img-fluid`}
          />
        </div>
      </Row>
    </div>
  );
};

export default Frame5;

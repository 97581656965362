import React from 'react';
import './AnimatedSVG.css'; // Import the CSS file for animations

const AnimatedSVG = () => {
    return (
        <div className="svg-container">
        <svg width="454" height="100%" padding-top="30px" viewBox="0 0 281 317" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="path1" opacity="0.7" d="M149.352 241.855C173.13 246.757 187.539 272.177 168.277 286.956C167.856 287.279 167.43 287.599 167 287.915C153.722 297.692 136.845 303.883 118.558 305.685C100.271 307.487 81.4182 304.818 64.4449 298.024C47.4717 291.229 33.1612 280.622 23.3696 267.58C13.578 254.537 8.75717 239.66 9.53241 224.879C10.3077 210.097 16.6432 196.093 27.7172 184.683C38.7913 173.273 54.0928 164.983 71.6372 160.889C77.6775 159.48 83.8877 158.59 90.1673 158.218C109.702 157.063 120.733 177.523 114.43 196.048C107.913 215.203 119.663 235.735 139.479 239.82L149.352 241.855Z" fill="url(#paint0_linear_1_1359)" />
            <path id="path2" opacity="0.7" d="M272.417 71.7887C282.977 93.6956 253.319 130.703 230.307 122.835C228.528 122.226 226.535 121.264 224.29 119.892C203.669 107.283 204.877 65.1459 212.853 52.0996C220.83 39.0534 236.997 44.6546 257.618 57.2627C264.765 61.6325 269.951 66.6747 272.417 71.7887Z" fill="url(#paint1_linear_1_1359)" />
            <path id="path3" opacity="0.7" d="M57.657 87.0889C46.8401 97.8974 71.5887 72.7552 54.5043 55.6574C37.4199 38.5597 28.6121 15.504 39.429 4.69548C50.7653 -6.63197 48.1219 19.3662 54.2491 34.1744C62.5687 54.2812 65.3151 79.4368 57.657 87.0889Z" fill="url(#paint2_linear_1_1359)" />
            <defs>
                <linearGradient id="paint0_linear_1_1359" x1="9.88717" y1="220.819" x2="194.425" y2="243.433" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CA51A1" />
                    <stop offset="1" stop-color="#CB0064" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_1359" x1="204.265" y1="66.1455" x2="262.044" y2="101.472" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CA51A1" />
                    <stop offset="1" stop-color="#CB0064" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_1359" x1="50.645" y1="-13.4707" x2="90.6291" y2="26.5447" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#CA51A1" />
                    <stop offset="1" stop-color="#CB0064" />
                </linearGradient>
            </defs>
        </svg>
</div>
    );
}

export default AnimatedSVG;

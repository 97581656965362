import React from 'react';

const SvgBackground = ({ isActive }) => {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="79" height="78" viewBox="0 0 79 78" fill="none" className={isActive ? 'active' : ''}>
    //   <circle cx="39.5" cy="39" r="39" fill="url(#paint0_linear_287_1418)" fillOpacity={isActive ? "1" : "0.05"} stroke={isActive ? "#9300B8" : "none"} strokeWidth={isActive ? "1" : "0"}/>
    //   <defs>
    //     <linearGradient id="paint0_linear_287_1418" x1="0.5" y1="39" x2="78.5" y2="39" gradientUnits="userSpaceOnUse">
    //       <stop stopColor={isActive ? "#9300B8" : "rgba(147, 0, 184, 0.05)"}/>
    //       <stop offset="1" stopColor={isActive ? "#CB0064" : "rgba(203, 0, 100, 0.05)"}/>
    //     </linearGradient>
    //   </defs>
    // </svg>

    <div className={`button-container ${isActive} ? 'active' : ''`}>



    </div>
  );
};

export default SvgBackground;

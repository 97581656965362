// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_paginationContainer__zYu1Z {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.75rem;
  }
  
  .Pagination_pageButton__OCM1Z {
    border: none;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0 0.25rem;
    font-size: 1rem;
  }
  
  .Pagination_pageButton__OCM1Z:hover {
    background-color: #CB0064; /* Hover background color */
    color: white; /* Hover text color */
    border-radius: 5px;
    width: 2rem;
    height:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Pagination_activePage__6ASX6 {
    background-color: #CB0064; /* Active page background color */
    color: white; /* Active page text color */
    border-radius: 5px;
    width: 2rem;
    height:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Pagination_arrowButton__zzjji {
    border: none;
    background-color: transparent;
    color: #CB0064;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 0.25rem;
  }
  
  /* .arrowButton:hover {
    background-color: #E0E0E0; 
  } */
  
  .Pagination_arrowButton__zzjji:disabled {
    color: #CCC; /* Disabled arrow button color */
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Pagination.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,6BAA6B;IAC7B,WAAW;IACX,eAAe;IACf,eAAe;IACf,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,yBAAyB,EAAE,2BAA2B;IACtD,YAAY,EAAE,qBAAqB;IACnC,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB,EAAE,iCAAiC;IAC5D,YAAY,EAAE,2BAA2B;IACzC,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,6BAA6B;IAC7B,cAAc;IACd,eAAe;IACf,eAAe;IACf,iBAAiB;EACnB;;EAEA;;KAEG;;EAEH;IACE,WAAW,EAAE,gCAAgC;IAC7C,mBAAmB;EACrB","sourcesContent":[".paginationContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 3.75rem;\n  }\n  \n  .pageButton {\n    border: none;\n    background-color: transparent;\n    color: #333;\n    cursor: pointer;\n    padding: 0.5rem;\n    margin: 0 0.25rem;\n    font-size: 1rem;\n  }\n  \n  .pageButton:hover {\n    background-color: #CB0064; /* Hover background color */\n    color: white; /* Hover text color */\n    border-radius: 5px;\n    width: 2rem;\n    height:2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .activePage {\n    background-color: #CB0064; /* Active page background color */\n    color: white; /* Active page text color */\n    border-radius: 5px;\n    width: 2rem;\n    height:2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .arrowButton {\n    border: none;\n    background-color: transparent;\n    color: #CB0064;\n    cursor: pointer;\n    font-size: 1rem;\n    margin: 0 0.25rem;\n  }\n  \n  /* .arrowButton:hover {\n    background-color: #E0E0E0; \n  } */\n  \n  .arrowButton:disabled {\n    color: #CCC; /* Disabled arrow button color */\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": `Pagination_paginationContainer__zYu1Z`,
	"pageButton": `Pagination_pageButton__OCM1Z`,
	"activePage": `Pagination_activePage__6ASX6`,
	"arrowButton": `Pagination_arrowButton__zzjji`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useRef } from "react";
import LogoCreationPage from "./Homepage";
import LogoCards from "../components/LogoCards";
import Testimonials from "../components/Testimonials";
import Navbar from "../components/Navbar";
import WhyChoseUs from "../components/WhyChoseUs";
import CustomLogoMaker from "../components/CustomLogoMakers";
import TwoColumnsSection, { TwoColumnsSection1, TwoColumnsSection2, TwoColumnsSection3 } from "../components/TwoColumnsSection";
import MyServices from "../components/Services";
import Footer from "../components/Footer";
import FAQSection from "../components/FAQsection";
import FooterSection from "../components/FooterSection";
import group1 from '../assets/Group1.png';
import group2 from '../assets/Group2.png';
import group3 from '../assets/Group3.png';
import group4 from '../assets/Group4.png';
import group5 from '../assets/Group5.png';
import group6 from '../assets/Group6.png';
import "../components/MakeLogo.css";

const firstRowData = [
  {
    logo: group3,
    heading: "Rapid Logo Generation",
    subheading: "Get instant logo options generated within seconds, saving you time and effort in the design process.",
  },
  {
    logo: group2,
    heading: "Customizable Elements",
    subheading: "Tailor your logo with a wide range of fonts, colors, icons, and shapes to match your brand identity perfectly.",
  },
  {
    logo: group1,
    heading: "Instant Preview",
    subheading: "Instantly visualize how your logo will look on various platforms, including websites, social media, and business cards.",
  },

];

const secondRowData = [
  {
    logo: group4,
    heading: "Extensive Template Library",
    subheading: "Choose from a diverse collection of pre-designed templates to kickstart your logo design process.",
  },
  {
    logo: group5,
    heading: "User-Friendly Interface",
    subheading: "Our intuitive interface makes it easy for anyone to create professional logos without any design experience.",
  },
  {
    logo: group6,
    heading: "Downloadable Formats",
    subheading: "Download your logo in high-resolution PNG, JPEG, and vector formats for seamless integration across different platforms.",
  }
]
const LogoMakerpage = () => {
  const logoCardsRef = useRef(null);

  const scrollToLogoCards = () => {
    if (logoCardsRef.current) {
      logoCardsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <div
        className="navbarOuterContainer"

      >
        <Navbar />
      </div>

      <div
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto',
        }}
      >
        <LogoCreationPage scrollToLogoCards={scrollToLogoCards} />
      </div>

      <div
        className="overflowHidden"
        style={{
          background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto',
          paddingLeft: '1rem', paddingRight: '1rem'
        }}
        ref={logoCardsRef}>
        <LogoCards />
      </div>
      <div
        className="overflowHidden"
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', paddingLeft: '1rem', paddingRight: '1rem'
        }}
      >
        <Testimonials />
      </div>
      <div
        className="overflowHidden"
        style={{
          background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto',
          paddingLeft: '1rem', paddingRight: '1rem'
        }}
      >
        <WhyChoseUs 
        firstRowData={firstRowData} 
        secondRowData={secondRowData} 
        
        />
      </div>
      <div
        style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto',
          paddingLeft: '1rem', paddingRight: '1rem'
        }}
      >
        <CustomLogoMaker />
      </div>
      <div style={{ background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', paddingLeft: '1rem', paddingRight: '1rem' }}>
        <TwoColumnsSection />
      </div>
      <div style={{ background: "#FFFFFF", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', paddingLeft: '1rem', paddingRight: '1rem' }}>
        <TwoColumnsSection1 />
      </div>
      <div style={{ background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', paddingLeft: '1rem', paddingRight: '1rem' }}>
        <TwoColumnsSection2 />
      </div>
      <div style={{ background: "#FFFFFF", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', paddingLeft: '1rem', paddingRight: '1rem' }}>
        <TwoColumnsSection3 />
      </div>
      <div style={{ background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
        <MyServices />
      </div>
      <div style={{ background: "#FFFFFF", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', paddingLeft: '1rem', paddingRight: '1rem' }}>
        <FAQSection />
      </div>
      <div className="d-none d-lg-flex" style={{ background: "#F8F8F8", justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
        <FooterSection />
      </div>
      <Footer />
    </>
  );
};

export default LogoMakerpage;

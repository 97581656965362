import React, { useMemo, useContext, useEffect, useRef, useState } from 'react';
import { LogoContext } from '../contexts/LogoGeneratorContext';
import styled from 'styled-components';
import { downloadImage } from '../utils/downloadImage';
import { getDirection, getFlexDirection } from '../utils/layout';
import ReactCurvedText from "react-curved-text";
import axios from 'axios';
import { baseURL } from '../config/config';

const StyledIcon = styled.svg`
  ${({ theme, size, color }) =>
    css`
      width: 14rem;
      height: 14rem;
      fill: none;
  
    `}
`;

const shuffleArray = (array) => {
  const shuffled = array.slice();
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const Icon = ({ children, ...rest }) => {
  return (
    <StyledIcon
      viewBox="0 0 24 24"

      {...rest}
    >
      {children}
    </StyledIcon>
  );
};

const StyledLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
 
  background-color: transparent;
  gap: 0.5rem;
  padding: 0;
  direction: ${({ logo }) => getDirection(logo)};
  flex-direction: ${({ logo }) => getFlexDirection(logo)};
  width: 17rem;
`;
// color: ${({ logo }) => logo.color};
// font-size: ${props => props.fontSize}rem;
const StyledLogoText = styled.div`
  color: black;
  letter-spacing: 1px;
  font-weight: 400;
  text-wrap:nowrap;
 font-family: ${props => props.fontFamily};
 font-size: ${props => props.fontSize};

  color: ${props => props.color};
  
`;


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 220px;
  height: 195px;
`;

const CurvedTextWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Logo = styled.img`
  max-width: 6.5rem;
  max-height: 6.5rem;
  width: auto;
  height: auto;
  object-fit: contain;
  position: relative;
  z-index: 1;
`;

const getTextPathLength = (text, fontSize, fontFamily) => {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
  
  // Corrected string interpolation for font size and family
  textElement.setAttribute("style", `font-size: ${fontSize}px; font-family: ${fontFamily}`);
  
  textElement.textContent = text;
  svg.appendChild(textElement);
  
  // Temporarily add to DOM to calculate length
  document.body.appendChild(svg);
  const length = textElement.getComputedTextLength();
  
  // Remove the SVG from the DOM
  document.body.removeChild(svg);
  
  return length;
};

const adjustFontSizeToFitPath = (text, initialFontSize, fontFamily, ellipsePathWidth) => {
  let fontSize = initialFontSize;
  let textLength = getTextPathLength(text, fontSize, fontFamily);

  // Adjust until the text fits within the desired width
  while (textLength > ellipsePathWidth && fontSize > 0.1) {
    fontSize -= 0.1;
    textLength = getTextPathLength(text, fontSize, fontFamily);
  }

  return fontSize;
};



const layouts = ["Icon-Left", "Icon-Right", "Icon-Top", "Icon-Bottom"];

const fonts = [
  { family: 'Sans Serif', font: 'Arial, Helvetica, sans-serif', },
  { family: 'Serif', font: 'Times New Roman, Times, serif', },
  { family: 'Script', font: 'Great Vibes, cursive', fontSize: '1.4rem' },
  { family: 'Display', font: 'Impact, Charcoal, sans-serif', },
  { family: 'Monospaced', font: 'Courier, monospace', },
];

const loadGoogleFont = (fontName) => {
  const link = document.createElement('link');
  link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(/ /g, '+')}&display=swap`;
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};

export const fontCategories = {
  serif: [
    'Merriweather', 'Playfair Display', 'Roboto Slab', 'Lora', 'Slabo 27px',
    'Crimson Text', 'Bitter', 'Alegreya', 'Noto Serif', 'Cardo',
    'Arvo', 'Cormorant Garamond', 'Bree Serif', 'Tinos', 'Zilla Slab',
    'Cinzel', 'EB Garamond', 'Libre Baskerville', 'Spectral', 'Vollkorn'
  ],
  sansSerif: [
    'Roboto', 'Open Sans', 'Lato', 'Montserrat', 'Oswald',
    'Raleway', 'PT Sans', 'Ubuntu', 'Noto Sans', 'Work Sans',
    'Poppins', 'Source Sans Pro', 'Exo 2', 'Titillium Web', 'Nunito Sans',
    'Fira Sans', 'Hind', 'Muli', 'Barlow', 'Cabin'
  ],
  script: [
    'Pacifico', 'Dancing Script', 'Great Vibes', 'Satisfy', 'Courgette',
    'Indie Flower', 'Yellowtail', 'Caveat', 'Kaushan Script', 'Shadows Into Light',
    'Allura', 'Cookie', 'Alex Brush', 'Sacramento', 'Bad Script',
    'Lobster Two', 'Marck Script', 'Parisienne', 'Tangerine'
  ],
  monospaced: [
    'Roboto Mono', 'Courier Prime', 'IBM Plex Mono',
    'Fira Code', 'Inconsolata', 'Anonymous Pro', 'Oxygen Mono', 'VT323',
    'Cousine', 'Cutive Mono', 'Share Tech Mono', 'Ubuntu Mono', 'Nova Mono',
    'Nanum Gothic Coding', 'Press Start 2P', 'PT Mono'
  ],
  display: [
    'Abril Fatface', 'Lobster', 'Baloo 2', 'Bangers', 'Fredericka the Great',
    'Josefin Sans', 'Righteous', 'Amatic SC', 'Luckiest Guy', 'Anton',
    'Chewy', 'Play', 'Patua One',
    'Staatliches', 'Poiret One',  'Yatra One', 'Fruktur'
  ]
};

const industryMapping = {
  "Automotives and Transportation": 1,
  "Business & Consulting": 2,
  "Food, Beverage, Restaurant": 3,
  "Travel & Holidays": 4,
  "IT & Science": 5,
  "Marketing": 6,

};

const getRandomElement = (array) => array[Math.floor(Math.random() * array.length)];

const useGenerateLogoComponents = (memoizedSelectedFonts, selectedLayouts, logoText, logoIndustry, logoStyles) => {
  const [apiLogos, setApiLogos] = useState([]);
  const [iconLogos, setLogos] = useState([]);
  const [industryId, setIndustryId] = useState(null);
  const initialized = useRef(false);
 

  //const [adjustedFontSize, setAdjustedFontSize] = useState(20);


  useEffect(() => {
   
    initialized.current = false;

  }, [apiLogos]);

  useEffect(() => {
    // Fetch logos based on industryId
    const fetchLogos = async () => {
      try {
        console.log('industryId', industryId);
        console.log('logoStyles', logoStyles);

        const relevantStyles = logoStyles.filter(style => style === 'Icon' || style === 'Geometry');
        const fetchPromises = relevantStyles.map(style =>
          //axios.get(`https://backend.alldesignsstudio.com/api/images/${industryMapping[logoIndustry]}/${style}`)
          axios.get(`${baseURL}/images/${industryMapping[logoIndustry]}/${style}`)
        );

        const responses = await Promise.all(fetchPromises);
        const combinedData = responses.flatMap(response => response.data);

      
        setApiLogos(combinedData);
      } catch (error) {
        console.error('Error fetching logos:', error);
      }
    };

    fetchLogos();
  }, [industryId, logoStyles]);

  const containerWidth = 17;
  const maxFontSize = 1.6;
  const minFontSize = 0.6;
  const maxIconWidth = 7;
  const minIconWidth = 4;

  // const filteredFonts = Object.entries(fontCategories)
  //   .filter(([category]) => selectedFonts.includes(category))
  //   .flatMap(([category, fonts]) => fonts);
  const filteredFonts = useMemo(() => {
    return Object.entries(fontCategories)
      .filter(([category]) => memoizedSelectedFonts.includes(category))
      .flatMap(([category, fonts]) => fonts);
  }, [memoizedSelectedFonts, fontCategories]);

  const filteredLayouts = layouts.filter(layout => selectedLayouts.includes(layout));

  const [iconWidth, setIconWidth] = useState(maxIconWidth);


  useEffect(() => {
    filteredFonts.forEach(font => loadGoogleFont(font));
    console.log('filteredLayouts', filteredFonts.length);
  }, [filteredFonts]);



  useEffect(() => {
    if (initialized.current) return; // Skip if already initialized

    if (filteredFonts.length === 0 || filteredLayouts.length === 0) {
      setLogos([]);
      return;
    }


    const newLogos = apiLogos.flatMap((Icon, iconIndex) =>
      filteredLayouts.map((layout, layoutIndex) => {
        const randomFontForThisLogo = getRandomElement(filteredFonts);

        let adjustedIconSize;
        let adjustedFontSize;
        let customFontSize = 20;
        if (logoText.length <= 10) {
      
          adjustedFontSize = maxFontSize;
          adjustedIconSize = maxIconWidth;

        } else if (logoText.length > 10 && logoText.length <= 15) {
          
          adjustedFontSize=(((maxFontSize + minFontSize) / 2)+0.3); 
          adjustedIconSize = ((maxIconWidth + minIconWidth) / 2) - 0.8; 
        }
        else if (logoText.length > 15 && logoText.length <= 20) {
          
          adjustedFontSize=minFontSize + 0.4;
          adjustedIconSize = minIconWidth ;
        }
        else if (logoText.length > 20 && logoText.length <= 24) {
          
          adjustedFontSize=minFontSize + 0.2;
          adjustedIconSize = minIconWidth - 0.6;
        }
        else if (logoText.length > 24) {
          customFontSize = 16;
          adjustedFontSize = minFontSize;
          adjustedIconSize = minIconWidth;
        }


        return layout === "Icon-Bottom" ? (
          <Wrapper key={`${iconIndex}-${layoutIndex}`}>
            <CurvedTextWrapper>
              <ReactCurvedText
                width={220}
                height={190}
                cx={110}
                cy={140}
                rx={89}
                ry={96}
                              
                startOffset='25%'
                reversed={true}
                text={logoText}
                textProps={{ style: { fontSize: `${customFontSize}px`, fontFamily: randomFontForThisLogo } }}
                textPathProps={{ fill: Icon.color, textAnchor:'middle' }}
                tspanProps={{ dy: '-8' }}
              />
            </CurvedTextWrapper>
            {/* <Logo src={`https://backend.alldesignsstudio.com/uploads/${Icon.image_path}`} alt={`${Icon.name}`} /> */}
            <Logo src={`${baseURL}/uploads/${Icon.image_path}`} alt={`${Icon.name}`} />
          </Wrapper>
        ) : (
          <StyledLogoBox key={`${iconIndex}-${layoutIndex}`} logo={layout}>
            <img
              src={`${baseURL}/uploads/${Icon.image_path}`}
              // src={`https://backend.alldesignsstudio.com/uploads/${Icon.image_path}`}
              alt={`${Icon.name}`}
              style={{
                maxWidth: `${adjustedIconSize}rem`,
                maxHeight: '6rem',
                width: 'auto',
                height: 'auto',
                objectFit: 'contain'
              }}
            />
            <StyledLogoText fontFamily={randomFontForThisLogo} fontSize={`${adjustedFontSize}rem`} color={Icon.color} >
              {/* <ScaleText widthOnly={true} >
                <p className="child"> {logoText}</p>
              </ScaleText> */}
              {logoText}
            </StyledLogoText>

          </StyledLogoBox>
        );
      })
    );

    setLogos(shuffleArray(newLogos));
    initialized.current = true; // Set flag to true after initialization
  }, [filteredFonts, filteredLayouts, logoText]);

  return iconLogos;
};

export const AutoResizeText = ({ text, font, maxFontSize, minFontSize, containerWidth, maxIconWidth, minIconWidth, setIconWidth }) => {
  const textRef = useRef(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const adjustSizes = () => {
      if (!textRef.current) return;

      // Calculate initial available width for text
      let availableWidthForText = containerWidth - maxIconWidth - parseFloat(getComputedStyle(textRef.current).gap);
      let currentFontSize = maxFontSize;
      textRef.current.style.fontSize = `${currentFontSize}rem`;

      // Adjust font size to fit text within available width
      while (textRef.current.scrollWidth > availableWidthForText && currentFontSize > minFontSize) {
        currentFontSize -= 0.05;
        textRef.current.style.fontSize = `${currentFontSize}rem`;
      }

      setFontSize(currentFontSize);

      // Calculate the remaining width for the icon
      const usedWidth = textRef.current.scrollWidth + parseFloat(getComputedStyle(textRef.current).gap);
      let remainingWidthForIcon = containerWidth - usedWidth;

      // Ensure the icon width stays within min and max bounds
      if (remainingWidthForIcon < minIconWidth) {
        remainingWidthForIcon = minIconWidth;
      } else if (remainingWidthForIcon > maxIconWidth) {
        remainingWidthForIcon = maxIconWidth;
      }

      setIconWidth(remainingWidthForIcon);
    };

    adjustSizes();
  }, [text, containerWidth, maxFontSize, minFontSize, maxIconWidth, minIconWidth, setIconWidth]);

  return (
    <StyledLogoText ref={textRef} font={font} fontSize={fontSize}>
      {text}
    </StyledLogoText>
  );
};

export default useGenerateLogoComponents;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#textsvg{
  font-size: 56px !important; }

#path1 {
    transform-origin: center;
    animation: expand 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  
  #path2 {
    animation: move 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
  #path3 {
    animation: moveSecond 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);


    animation-delay: 2s; 
  }
  
  @keyframes expand {
    0% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes move {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100px, 0); /* Adjust as per your desired movement */
    }
  }

  @keyframes moveSecond {
    0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(170px, 120px); /* Adjust as per your desired movement */
      }
    }

    .svg-container {
        position: relative;
        width: 411px; /* Adjust width as needed */
        height: 100%; /* Adjust height as needed */
        z-index: 1; /* Ensure the SVG is below the Carousel */
      }
`, "",{"version":3,"sources":["webpack://./src/components/AnimatedSVG.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B,EAAE;;AAE9B;IACI,wBAAwB;IACxB,6EAA6E;EAC/E;;EAEA;IACE,2EAA2E;EAC7E;EACA;IACE,iFAAiF;;;IAGjF,mBAAmB;EACrB;;EAEA;IACE;MACE,qBAAqB;IACvB;IACA;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE;MACE,0BAA0B;IAC5B;IACA;MACE,+BAA+B,EAAE,wCAAwC;IAC3E;EACF;;EAEA;IACE;QACI,0BAA0B;MAC5B;MACA;QACE,kCAAkC,EAAE,wCAAwC;MAC9E;IACF;;IAEA;QACI,kBAAkB;QAClB,YAAY,EAAE,2BAA2B;QACzC,YAAY,EAAE,4BAA4B;QAC1C,UAAU,EAAE,yCAAyC;MACvD","sourcesContent":["#textsvg{\n  font-size: 56px !important; }\n\n#path1 {\n    transform-origin: center;\n    animation: expand 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);\n  }\n  \n  #path2 {\n    animation: move 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);\n  }\n  #path3 {\n    animation: moveSecond 2s infinite alternate cubic-bezier(0.68, -0.55, 0.27, 1.55);\n\n\n    animation-delay: 2s; \n  }\n  \n  @keyframes expand {\n    0% {\n      transform: scale(0.7);\n    }\n    100% {\n      transform: scale(1);\n    }\n  }\n\n  @keyframes move {\n    0% {\n      transform: translate(0, 0);\n    }\n    100% {\n      transform: translate(-100px, 0); /* Adjust as per your desired movement */\n    }\n  }\n\n  @keyframes moveSecond {\n    0% {\n        transform: translate(0, 0);\n      }\n      100% {\n        transform: translate(170px, 120px); /* Adjust as per your desired movement */\n      }\n    }\n\n    .svg-container {\n        position: relative;\n        width: 411px; /* Adjust width as needed */\n        height: 100%; /* Adjust height as needed */\n        z-index: 1; /* Ensure the SVG is below the Carousel */\n      }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/hideScrollbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,wBAAwB,EAAE,gBAAgB;IAC1C,qBAAqB,EAAE,YAAY;EACrC","sourcesContent":[".react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {\n    display: none;\n  }\n  \n  .react-horizontal-scrolling-menu--scroll-container {\n    -ms-overflow-style: none; /* IE and Edge */\n    scrollbar-width: none; /* Firefox */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
// import './home.css';
import CustomCardCarousel from '../components/Card';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "../pages/Step1.module.css";
import FontStyle1 from "../assets/LogoLayout1.png";
import FontStyle2 from "../assets/LogoLayout2.png";
import FontStyle3 from "../assets/LogoLayout3.png";
import FontStyle4 from "../assets/LogoLayout4.png";
import FontStyle5 from "../assets/LogoLayout5.png";


const cardData = [
  {
    image: FontStyle2,
    description: "Icon Above",

  },
  {
    image: FontStyle1,
    description: "Curved Text",

  },
  {
    image: FontStyle3,
    description: "Icon Right",


  },
  {
    image: FontStyle5,
    description: "Icon Left",

  },
  // {
  //   image: FontStyle4,
  //   description: "Icon Left",

  // },

];


const BodySection = () => {
  return (
    <div>
        <div className={`col-12 col-md-12 ${styles.headingContainer}`} style={{ padding: 0 }}>
        <h1 className={`${styles.stepheading} text-md-left`} >
          Step 3/3
        </h1>
        <h2 className={`${styles.logoSelectionHeading} text-md-left`}>
          Select all of the Logo layouts you like
        </h2>
        <p className={`${styles.preferencestext} text-md-left`}>
          Your selections will help us understand your preferences.
        </p>
      </div>

      <CustomCardCarousel 
      cardData={cardData} 
      height={'112.5rem'} 
      display={'none'} 
      paddingTop={'1.5rem'} 
      fontFamily={`'Gilroy-Medium',sans-serif`} 
      dataType='layout'
      />


    </div>
  );
};

export default BodySection;


import React, { useState, useEffect } from "react";
import doubleQuotes from "../assets/mark quo.png";
import Ellipse1 from "../assets/Ellipse 3 (1).png";
import Ellipse2 from "../assets/Ellipse 3 (2).png";
import Ellipse3 from "../assets/Ellipse 3.png";
import Stars from "../assets/stars.png";
import "./MakeLogo.css";
import styles1 from "../pages/CustomDesignServices.module.css";

const testimonialsData = [
  {
   
    review: "TheLogoMaker.ai made the whole process of creating a logo incredibly easy and stress-free. I had a rough idea of what I wanted, and their team brought it to life perfectly. The end result was a logo that truly represents my brand. Highly recommend their services!",
    image: Ellipse3,
    reviewer_name: "Sarah J,",
    reviewer_occupation: "Small Business Owner",
  },
  {
    
    review: "I struggled for months trying to design a logo myself with no success. TheLogoMaker.ai not only provided a beautiful design but also did it quickly. Their designers understood my vision and delivered exactly what I needed. Now, my business has a professional look that attracts more clients.",
    image: Ellipse1,
    reviewer_name: "David M,",
    reviewer_occupation: "Freelance Photographer",
  },
  {
    
    review: "Working with TheLogoMaker.ai was a game-changer for my startup. I needed a unique and memorable logo to stand out in a competitive market, and they delivered beyond my expectations. The process was smooth, and the team was responsive to all my feedback. I'm thrilled with the final design!",
    image: Ellipse2,
    reviewer_name: "Emily R,",
    reviewer_occupation: "Tech Entrepreneur",
  },
];

const Testimonials = ({ testimonialsSectionData, footerBackgroundColor, backgroundColor }) => {
  console.log('Props:', { testimonialsSectionData, footerBackgroundColor, backgroundColor });
  const [data, setData] = useState({});
  const [nestedData, setNestedData] = useState(null);
  const [smallCurrentIndex, setSmallCurrentIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (testimonialsSectionData){
      setData(testimonialsSectionData);
    setNestedData(testimonialsSectionData.reviews);}
    console.log('faqSectionData', testimonialsSectionData);
  }, [testimonialsSectionData]);


  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleSmallBack = () => {
    setSmallCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleSmallNext = () => {
    setSmallCurrentIndex((prevIndex) => Math.min(prevIndex + 1, testimonialsData.length - 1));
  };

  return (
    <div className={`testimonialSection text-center col-xs-6 ${styles1.TestimonialSection} `}>
      <h2 className="sectionHeading" style={{ marginBottom: '0.62rem' }}>
        {data.heading ? data.heading : "What Our Customers Say About Our Logo Maker AI"}
        {data.heading?
          (<span className="questionMark">.</span>)
          : (
            <span>
              <img src={Stars} alt="Stars" className="inline-block-visible" style={{ padding: 0, marginLeft: '0.69rem', }} />
            </span>
          )
        }
      </h2>
      <p className="sectionDescription" style={{ marginBottom: '2.62rem' }}>
        {data.subheading ? data.subheading : ""}
      </p>

      {/* Carousel for small screens */}
      <div className={`${styles1.stepsButtonsContainer} d-lg-none d-md-flex d-flex` }>
      {(nestedData ? nestedData : testimonialsData).map((testimonial, index) => (
              <div key={index}>
                <div className="card " style={{ border: 'none', width: 'fitContent', paddingLeft: 0, paddingRight: 0 }}>
                  <div className="card-body test-card-body" style={{ backgroundColor: backgroundColor }}>
                    <div className="text-left" style={{ textAlign: 'left' }}>
                      <img src={doubleQuotes} alt="doubleQuotes" style={{ textAlign: 'left' }} />
                    </div>
                    <p className="test-card-text mt-3 text-left" style={{ textAlign: 'left' }}>{testimonial.review}</p>
                  </div>
                  <div className="card-footer d-flex align-items-center" style={{ gap: '0.62rem', backgroundColor: footerBackgroundColor }}>
                  {!nestedData &&
                    <img
                      src={testimonial.image}
                      alt={testimonial.reviewer_name}
                      className="rounded-circle mr-3"
                      style={{ width: "50px", height: "50px" }}
                    />
                  }
                    <div style={{ marginBottom: 0, paddingTop: '1rem' }}>
                      <h5 className="mb-0 test-name">{testimonial.reviewer_name}</h5>
                      <p className="test-detail">{testimonial.reviewer_occupation}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
  



      {/* Grid for larger screens */}
      <div className=" d-lg-flex flex-row large-screen justify-content-center"
        style={{ gap: '1.62rem' }}
      >
        {(nestedData ?nestedData : testimonialsData).map((testimonial, index) => (

          <div className="card " style={{ border: 'none', width: 'fitContent', paddingLeft: 0, paddingRight: 0 }}>
            <div className="card-body test-card-body" style={{ backgroundColor: backgroundColor }}>
              <div className="text-left" style={{ textAlign: 'left' }}>
                <img src={doubleQuotes} alt="doubleQuotes" style={{ textAlign: 'left' }} />
              </div>
              <p className="test-card-text mt-3 text-left" style={{ textAlign: 'left' }}>{testimonial.review}</p>
            </div>
            <div className="card-footer d-flex align-items-center" style={{ gap: '0.62rem', backgroundColor: footerBackgroundColor }}>
             {!nestedData &&
              <img
                src={testimonial.image}
                alt={testimonial.reviewer_name}
                className="rounded-circle mr-3"
                style={{ width: "50px", height: "50px" }}
              />}
              <div style={{ marginBottom: 0, paddingTop: '1rem' }}>
                <h5 className="mb-0 test-name">{testimonial.reviewer_name}</h5>
                <p className="test-detail">{testimonial.reviewer_occupation}</p>
              </div>
            </div>
          </div>

        ))}
      </div>
    </div >
  );
};

export default Testimonials;

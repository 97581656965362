import React from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import styles from "./Footer.module.css";
import linkedin from "../assets/linkedin.svg";
import insta from "../assets/Insta.svg";
import twitter from "../assets/twitter.svg";
import facebook from "../assets/facebook.svg";
import logo from "../assets/ADS_Logo_white.svg";
import envelop from "../assets/Envelop.png";
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <div className={`${styles.container} `}>
            <div className={`${styles.footerRow} `}>
                <div className={styles.firstColContainer}>
                    <div className={styles.logoMakerParent}>
                        <img
                            className={styles.groupItem}
                            alt="Logo Maker Logo"
                            src={logo}
                        />
                    </div>
                    <div className={styles.loremIpsumDolor}>
                        Lorem ipsum dolor amet consectetur adipiscing elit do eiusmod tempor
                        incididunt ut labore et dolore.
                    </div>
                    <div className={[styles.socialLinks, styles.item - 3].join(' ')}>
                        <Link to='https://www.facebook.com/' className={styles.noLinkStyle}><img className={styles.facebookIcon} alt="facebook" src={facebook} /></Link>
                        <Link to='https://www.twitter.com/'><img className={styles.facebookIcon} alt="twitter" src={twitter} /></Link>
                        <Link to='https://www.instagram.com/'><img className={styles.facebookIcon} alt="instagram" src={insta} /></Link>
                        <Link to='https://www.linkedin.com/'><img className={styles.facebookIcon} alt="linkedin" src={linkedin} /></Link>


                    </div>

                </div>
                <div className={`d-flex justify-content-center align-items-center flex-row ${styles.midColGap}`} >
                    <div className={styles.links}>
                        <div className={styles.company}>Company</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flexStart', flexDirection: 'column', gap: "0.88rem" }}>
                            <div className={styles.singleService}>About company</div>
                            <div className={styles.singleService}>Company services</div>
                            <div className={styles.singleService}>Job opportunities</div>
                            <div className={styles.singleService}>Creative people</div>
                            <div className={styles.singleService}>Contact us</div>
                        </div>
                    </div>

                    <div className={`${styles.links}`}>
                        <div className={styles.company}>Customer</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flexStart', flexDirection: 'column', gap: "0.88rem" }}>
                            <div className={styles.singleService}>Client Support</div>
                            <div className={styles.singleService}>Latest News</div>
                            <div className={styles.singleService}>Company Story</div>
                            <div className={styles.singleService}>Services</div>
                            <div className={styles.singleService}>Who we are</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={[styles.links, styles.thirdColumn].join(' ')} style={{ marginLeft: '2.76rem' }}>
                        <div className={styles.company}>Subscribe To Newsletter</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flexStart', flexDirection: 'column', gap: '0.75rem' }}>
                            <div className={styles.emailText}>Enter your email address for receiving valuable newsletters.</div>
                            <div className={styles.enterYourEmail1}>
                                <input type='text' placeholder='Enter your email address'
                                    className={styles.customInput}
                                    style={{
                                        backgroundColor: '#141414', color: 'white', width: '16.875rem',
                                        height: '3rem', borderRadius: '0.3125rem', border: '1px solid #FFF', borderRight: 'none', paddingBottom: '0.49rem'
                                    }}
                                    onFocus={(e) => {
                                        e.target.style.borderColor = '#CB0064';
                                    }}
                                    onBlur={(e) => {
                                        e.target.style.borderColor = '#FFF';
                                    }}

                                />
                                <img src={envelop} alt='envelopIcon' style={{
                                    position: 'relative', height: '3.24rem', width: '3rem', marginLeft: '-4px'
                                }} />
                            </div>

                            <div className={styles.emailCopy} style={{ marginTop: '0.7rem' }}>&copy; Copyright 2023-</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;
import React from 'react';
import AnimatedSVG from '../components/AnimatedSVG';

function App() {
  return (
    <div className="App" style={{width:'350rem', height:'auto'}}>
      <AnimatedSVG />
    </div>
  );
}

export default App;

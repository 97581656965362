import React, { useState } from 'react';
import './MakeLogo.css';
import styles1 from "../pages/CustomDesignServices.module.css";
import SvgBackground from './PricingCardTag';
const CustomPricingCard = ({heading, price, labelText, para, image, alt}) => {


    return (

        <div className={styles1.cardWrapper}>
            <div className={styles1.imageContainer}>
                <img src={image} alt={alt} style={{width:'100%', height:'100%', borderRadius: '0.625rem 0.625rem 0rem 0rem'}}/>
            </div>
            <div className={styles1.pricingCardBody}>
           
                <h3 className={styles1.stepHeading}>{heading}</h3>
                <p className={styles1.stepPara} style={{width: '22.375rem'}}>{para}</p>
                <p className={styles1.pricingCardLabel}>{price}
                    <span className={styles1.pricingCardTag}> {labelText} </span>
                    </p>
            </div>
        </div>

    );
};

export default CustomPricingCard;

import React, { useState, useRef, useEffect } from "react";
import styles1 from "../pages/CustomDesignServices.module.css";
import image1 from '../assets/Pricing1.png';
import image2 from '../assets/Pricing3.png';
import image3 from '../assets/Pricing2.png';
import image4 from '../assets/PricingThird.png';
import CustomPricingCard from '../components/CustomPricingCard';
import { useNavigate } from 'react-router-dom';
import './MakeLogo.css';


const cardData = [
    {
        heading: 'Logo & brand identity pack',
        price: 'From $999',
        labelText: '30% Off Today',
        image: image1,
        para: 'Get all the digital and print essentials you need to kick-start your business.',
        alt: "PricingImage1"
    },
    {
        heading: 'Logo & brand identity pack',
        price: 'From $499',
        labelText: '30% Off Today',
        image: image3,
        para: 'Get all the digital and print essentials you need to kick-start your business.',
        alt: "PricingImage2"
    },
    {
        heading: 'Logo & brand identity pack',
        price: 'From $399',
        labelText: '30% Off Today',
        image: image2,
        para: 'Get all the digital and print essentials you need to kick-start your business.',
        alt: "PricingImage3"
    },
    {
        heading: 'Logo & brand identity pack',
        price: 'From $ 399',
        labelText: '30% Off Today',
        image: image4,
        para: 'Get all the digital and print essentials you need to kick-start your business.',
        alt: "PricingImage4"
    }
]

const CustomPricingComponent = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef(null);
    const [smallCurrentIndex, setSmallCurrentIndex] = useState(0);
    const [isNearEdge, setIsNearEdge] = useState(false);
    const navigate = useNavigate();

    const handleMouseLeave = () => {
        clearInterval(intervalRef.current);
    };



    const sliderContainerRef = useRef(null);
    const cardCarouselRef = useRef(null);

    useEffect(() => {
        const sliderContainerWidth = sliderContainerRef.current.offsetWidth;
        const cardWidth = 231; // Assuming each card has a width of 231px
        const totalCardWidth = cardWidth * cardData.length;

        if (cardCarouselRef.current) {
            cardCarouselRef.current.style.width = `${totalCardWidth}px`;
        }
    }, [cardData.length]);




    const handleMouseMove = (event) => {
        const carouselWidth = event.currentTarget.offsetWidth;
        const mouseX = event.clientX - event.currentTarget.getBoundingClientRect().left;

        if (mouseX < carouselWidth * 0.3 && !isNearEdge) {
            // Near the left side
            setCurrentIndex(0);
            setIsNearEdge(true); // Prevent repeated setting
        } else if (mouseX > carouselWidth * 0.7 && !isNearEdge) {
            // Near the right side
            setCurrentIndex(cardData.length - 2);
            setIsNearEdge(true); // Prevent repeated setting
        } else if (mouseX >= carouselWidth * 0.1 && mouseX <= carouselWidth * 0.9) {
            setIsNearEdge(false); // Reset when not near the edges
        }
    };

    const handleSmallBack = () => {
        setSmallCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleSmallNext = () => {
        setSmallCurrentIndex((prevIndex) => Math.min(prevIndex + 1, cardData.length - 1));
    };


    return (
        <div className={`card-section text-center d-flex align-items-center flex-column ${styles1.OverflowHidden} ${styles1.pricingSection}`} style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
            <h2 className="sectionHeading" style={{ width: '28.4375rem' }}>
                Flexible Pricing Packages for Every Budget
                <span className="dotStyle">.</span>
            </h2>

            <div
                className={`slider-container d-none d-lg-flex ${styles1.customizeMargin}`}
                onMouseMove={handleMouseMove}
                // onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                // style={{ overflow: 'hidden', position: 'relative', marginTop: '1.62rem' }}
                ref={sliderContainerRef}
                style={{ overflow: 'hidden', position: 'relative', marginTop: '1.62rem', width: '100%', marginLeft: '28%' }}
            >
                <div
                    className="card-carousel"
                    style={{
                        transform: `translateX(-${(currentIndex * 100) / cardData.length}%)`,
                        transition: 'transform 0.5s ease-in-out',
                        display: 'flex',
                        gap: '1rem',
                        transition: 'transform 1.5s ease-in-out',
                        // width: "calc(231px* 5)"
                    }}
                >
                    {cardData.map((card, index) => (
                        <div key={index} style={{ width: 'fit-content', paddingLeft: 0, paddingRight: 0 }}>
                            <CustomPricingCard
                                heading={card.heading}
                                price={card.price}
                                labelText={card.labelText}
                                image={card.image}
                                para={card.para}
                                alt={card.alt}
                            />
                        </div>
                    ))}
                </div>
            </div>





            {/* Small screen layout */}
            {/* < div className="card-container-small-screen d-lg-none flex-column" >
                <div className="cardSlider"> */}
                    {/* <div
                        className="cardContainer"
                        style={{
                            transform: `translateX(-${smallCurrentIndex * 100}%)`,
                        }}
                    > */}
                     <div className={`${styles1.stepsButtonsContainer} d-lg-none d-md-flex d-flex` }>
                        {cardData.map((card, index) => (
                            <div key={index} style={{ width: 'fit-content', paddingLeft: 0, paddingRight: 0 }}>
                                <CustomPricingCard
                                    heading={card.heading}
                                    price={card.price}
                                    labelText={card.labelText}
                                    image={card.image}
                                    para={card.para}
                                />
                            </div>
                        ))}
                    </div>
                {/* </div>
                <div className="slider-controls d-flex justify-content-center mt-3">
                    <button
                        className={`slider-button ${smallCurrentIndex === 0 ? "active" : ""}`}
                        onClick={handleSmallBack}
                        disabled={smallCurrentIndex === 0}
                        name='swapButton1'
                    ></button>
                    <button
                        className={`slider-button ${smallCurrentIndex === cardData.length - 1 ? "active" : ""
                            }`}
                        onClick={handleSmallNext}
                        disabled={smallCurrentIndex === cardData.length - 1}
                        name='swapButton2'
                    ></button>
                </div>
            </div> */}
        </div>
    );
};

export default CustomPricingComponent;

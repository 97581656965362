import React, { useState, useEffect } from "react";
import LogoCreationPage from "./Homepage";
import LogoCards from "../components/LogoCards";
import Testimonials from "../components/Testimonials";
import WhyChoseUs from "../components/WhyChoseUs";
import CustomLogoMaker from "../components/CustomLogoMakers";
import CustomLogoHero from "../components/CustomLogoHero";
import CustomLogoSteps from "../components/CustomLogoSteps";
import CustomLogoPricing from "../components/CustomLogoPricing";
import CustomLogoScroll from "../components/CustomLogoScroll";
import CustomLogoCTA from "../components/CustomLogoCTA";
import CustomLogoExploreSection from "../components/CustomLogoExploreSection";
import CustomLogoBrowseSection from "../components/CustomLogosBrowseSection";
import CustomLogoOrderSection from "../components/CustomLogoOrderSection";
import TwoColumnsSection, { TwoColumnsSection1, TwoColumnsSection2, TwoColumnsSection3 } from "../components/TwoColumnsSection";
import MyServices from "../components/Services";
import Footer from "../components/Footer";
import FAQSection from "../components/FAQsection";
import FooterSection from "../components/FooterSection";
import group6 from '../assets/archive.svg';
import group5 from '../assets/chat.svg';
import group4 from '../assets/coin-stack.svg';
import group3 from '../assets/clock.svg';
import group2 from '../assets/infinite.svg';
import group1 from '../assets/WhyCustom1.svg';
import Navbar from "../components/Navbar";
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { baseURL } from '../config/config';
// const firstRowData = [
//   {
//     logo: group1,
//     caption: "Custom Designs",
//     description: "Each logo is crafted uniquely for your brand, ensuring it reflects your vision and values.",
//   },
//   {
//     logo: group2,
//     caption: "Unlimited Revisions",
//     description: "We offer unlimited revisions to make sure your logo is perfect and meets all your expectations.",
//   },
//   {
//     logo: group3,
//     caption: "Fast Turnaround",
//     description: "Receive your initial logo concepts within 72 hours so you can start building your brand quickly.",
//   },

// ];

// const secondRowData = [
//   {
//     icon: group4,
//     icon_alt_text:"",
//     heading: "Affordable Packages",
//     subheading: "We offer competitive pricing with various packages to suit different budgets and requirements.",
//   },
//   {
//     logo: group5,
//     caption: "Dedicated Support",
//     description: "Our customer support team is always ready to assist you throughout the design process, ensuring a smooth experience.",
//   },
//   {
//     logo: group6,
//     caption: "Scalable Vector Files",
//     description: "Get high-quality, scalable vector files that look great on everything from business cards to billboards.",
//   }
// ]


const LogoMakerpage = () => {
  const { slug } = useParams();
  const [pageId, setPageId] = useState(null);
  const [heroSectionData, setHeroSectionData] = useState(null);
  const [whySectionData, setWhySectionData] = useState(null);
  const [stepsSectionData, setStepsSectionData] = useState(null);
  const [browseSectionData, setBrowseSectionData] = useState(null);
  const [pricingSectionData, setPricingSectionData] = useState(null);
  const [testimonialsSectionData, setTestimonialsSectionData] = useState(null);
  const [faqSectionData, setFaqSectionData] = useState(null);
  const [seoSectionData, setSeoSectionData] = useState(null);
  const [ctaSectionData, setCtaSectionData] = useState(null);
  const [firstRowData, setFirstRowData] = useState([]);
  const [secondRowData, setSecondRowData] = useState([]);



  useEffect(() => {
    const fetchEntry = async () => {
      try {
        console.log('slug', slug);
        const id = await axios.get(`${baseURL}/get-id-by-slug/${slug}`);
        console.log('idddddd',id.data[0].id);
        setPageId(id.data[0].id);

        // const response = await axios.get(`https://backend.alldesignsstudio.com/api/banner-by-slug/${slug}`);
        // setHeroSectionData(response.data);

        // setLoading(false);
      } catch (err) {
        console.log(err.message);
        // setLoading(false);
      }
    };

    fetchEntry();
  }, [slug]);

  useEffect(() => {

  }, [pageId]);

  useEffect(() => {


    const fetchData = async () => {
      try {
        console.log('pageId', pageId);

        const response1 = await axios.get(`${baseURL}/banner-by-id/${pageId}`);
        setHeroSectionData(response1.data);

        const response2 = await axios.get(`${baseURL}/sectionTwo/${pageId}`);
        setWhySectionData(response2.data);
        

        const response3 = await axios.get(`${baseURL}/sectionThree/${pageId}`);
        setStepsSectionData(response3.data);

        const response4 = await axios.get(`${baseURL}/sectionFour/${pageId}`);
        setBrowseSectionData(response4.data);
        console.log("why section",response4.data);

        const response5 = await axios.get(`${baseURL}/reviews/${pageId}`);
        setTestimonialsSectionData(response5.data);

        const response6 = await axios.get(`${baseURL}/faqs/${pageId}`);
        setFaqSectionData(response6.data);

        const response7 = await axios.get(`${baseURL}/seoContent/${pageId}`);
        setSeoSectionData(response7.data);

        const response8 = await axios.get(`${baseURL}/ctaSections/${pageId}`);
        setCtaSectionData(response8.data);


      } catch (err) {
        console.log(err.message);
      } finally {
        // Uncomment this line if you are using loading state
        // setLoading(false);
      }

    };

    if (pageId) {
      fetchData();
    }
  }, [pageId]);


  useEffect(() => {
    if (whySectionData?.benefits) {
      const firstRow = whySectionData.benefits.slice(0, 3).map(item => ({
        icon: item.icon_path,
        icon_alt_text: item.icon_alt_text,
        heading: item.heading,
        subheading: item.subheading,
      }));

      const secondRow = whySectionData.benefits.slice(3).map(item => ({
        icon: item.icon_path,
        icon_alt_text: item.icon_alt_text,
        heading: item.heading,
        subheading: item.subheading,
      }));

      setFirstRowData(firstRow);
      setSecondRowData(secondRow);
    }
  }, [whySectionData]);

  function addMetaTag(name, content) {
    const meta = document.createElement('meta');
    meta.name = name;
    meta.content = content;
    document.head.appendChild(meta);
  }
  
  function setPageTitle(titleText) {
    const titleElement = document.createElement('title');
    titleElement.textContent = titleText;
    document.head.appendChild(titleElement);
  }
  
  

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div
        className="navbarOuterContainer"

      >
        <Navbar />
      </div>
      {heroSectionData &&
        <div
          className="smallPadding">
          <CustomLogoHero heroSectionData={heroSectionData} />
        </div>
      }
    {whySectionData && whySectionData.heading && whySectionData.subheading && whySectionData.benefits.length > 0 &&
        <div
          style={{
            background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto'
          }}
        >
          <WhyChoseUs
            firstRowData={firstRowData}
            secondRowData={secondRowData}
            sectionHeading={whySectionData.heading}
            sectionPara={whySectionData.subheading}
            marginTop='5rem'
            marginBottom='5.62rem'
          />
        </div>
      }

      {stepsSectionData && stepsSectionData.heading && stepsSectionData.subheading && stepsSectionData.steps.length > 0 &&
        <div
          className="smallPadding"
          style={{
            background: "#FFFFFF", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto',
          }}
        >
          <CustomLogoSteps stepsSectionData={stepsSectionData} marginBottom='6.87rem' marginTop='6.87rem' />
        </div>
      }

      {browseSectionData && browseSectionData.heading && browseSectionData.steps.length > 0 &&
        <div style={{ background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
          <CustomLogoBrowseSection browseSectionData={browseSectionData} />
        </div>
      }
      {pricingSectionData &&
        <div
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto'
          }}
        >
          <CustomLogoPricing />
        </div>
      }
      {testimonialsSectionData &&
        <div style={{
          background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto'
        }}
        >
          <Testimonials
            testimonialsSectionData={testimonialsSectionData}
            backgroundColor='#fff'
            footerBackgroundColor='#f8f8f8'
          />
        </div>
      }
      {faqSectionData &&
        <div className="px-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
          <FAQSection
            faqSectionData={faqSectionData}
            serviceFont='1.25rem'
            paddingBottom='5rem'
            paddingTop='5rem'
          />
        </div>
      }
      {seoSectionData &&
        <div className="px-3" style={{ background: "#F8F8F8", display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
          <CustomLogoScroll seoSectionData={seoSectionData} />
        </div>
      }

      {ctaSectionData &&
        <div className="px-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
          <CustomLogoOrderSection ctaSectionData={ctaSectionData} />
        </div>
      }


      <Footer />
    </>
  );
};

export default LogoMakerpage;

import React, { useState, useRef, useEffect } from 'react';
import '../stylesheet.css';
//import '..\assets\Colors\flat_design_colors_full.scss';

const initialsFonts = [
  { font: 'Hokia', fontSize: 2, minFontSize: 1.1 },
  { font: 'Avapore', fontSize: 2, minFontSize: 1.1 },
  { font: 'BackWild', fontSize: 2, minFontSize: 1.1 },
  { font: 'Befort', fontSize: 2, minFontSize: 1.1 },
  { font: 'Bladge', fontSize: 2, minFontSize: 1.1 },
  { font: 'BROTHER', fontSize: 2, minFontSize: 1.1 },
  { font: 'Cambridge', fontSize: 2, minFontSize: 1.1 },
  { font: 'ChalkZone', fontSize: 2, minFontSize: 1.1 },
  { font: 'chimers', fontSize: 2, minFontSize: 1.1 },
  { font: 'Choret', fontSize: 2, minFontSize: 1.1 },
  { font: 'Cropar', fontSize: 2, minFontSize: 1.1 },
  { font: 'Weird', fontSize: 2, minFontSize: 1.1 },
  { font: 'CSWilson', fontSize: 2, minFontSize: 1.1 },
  { font: 'DopeDisplay', fontSize: 2, minFontSize: 1.1 },
  { font: 'DreamSparks', fontSize: 2, minFontSize: 1.1 },
  { font: 'DubaiCity', fontSize: 2, minFontSize: 1.1 },
  { font: 'Elmirad', fontSize: 2, minFontSize: 1.1 },
  { font: 'EndlessFusion', fontSize: 2, minFontSize: 1.1 },
  { font: 'FaqroExtended', fontSize: 2, minFontSize: 1.1 },
  { font: 'Garde', fontSize: 2, minFontSize: 1.1 },
  { font: 'Gebriel', fontSize: 2, minFontSize: 1.1 },
  { font: 'Ghoip', fontSize: 2, minFontSize: 1.1 },
  { font: 'Goblock', fontSize: 2, minFontSize: 1.1 },
  { font: 'Korosu', fontSize: 2, minFontSize: 1.1 },
  { font: 'Krunc', fontSize: 2, minFontSize: 1.1 },
  { font: 'LeonSlab', fontSize: 2, minFontSize: 1.1 },
  { font: 'LUSER', fontSize: 2, minFontSize: 1.1 },
  { font: 'MAENGAME', fontSize: 2, minFontSize: 1.1 },
  { font: 'Magnode', fontSize: 2, minFontSize: 1.1 },
  { font: 'MoonWalk', fontSize: 2, minFontSize: 1.1 },
  { font: 'MORTHIX', fontSize: 2, minFontSize: 1.1 },
  { font: 'Neigh', fontSize: 2, minFontSize: 1.1 },
  { font: 'Nevla', fontSize: 2, minFontSize: 1.1 },
  { font: 'Nexusbold', fontSize: 2, minFontSize: 1.1 },
  { font: 'CFNotche', fontSize: 2, minFontSize: 1.1 },
  { font: 'Osake', fontSize: 2, minFontSize: 1.1 },
  { font: 'PixelImpact', fontSize: 2, minFontSize: 1.1 },
  { font: 'Proxemic', fontSize: 2, minFontSize: 1.1 },
  { font: 'Qmico', fontSize: 2, minFontSize: 1.1 },
  { font: 'Ragley', fontSize: 2, minFontSize: 1.1 },
  { font: 'Rambors', fontSize: 2, minFontSize: 1.1 },
  { font: 'Ransom', fontSize: 2, minFontSize: 1.1 },
  { font: 'RetroMonkey', fontSize: 2, minFontSize: 1.1 },
  { font: 'Rompax', fontSize: 2, minFontSize: 1.1 },
  { font: 'Siegra', fontSize: 2, minFontSize: 1.1 },
  { font: 'SpaceOdyssey', fontSize: 2, minFontSize: 1.1 },
  { font: 'SPORTNEWS', fontSize: 2, minFontSize: 1.1 },
  { font: 'SummerOf76', fontSize: 2, minFontSize: 1.1 },
  { font: 'Superline', fontSize: 2, minFontSize: 1.1 },
  { font: 'Voltstrom', fontSize: 2, minFontSize: 1.1 },
  { font: 'Waosh', fontSize: 2, minFontSize: 1.1 },
  { font: 'Zetan', fontSize: 2, minFontSize: 1.1 },
];



const fonts = [

  { font: 'Times New Roman, Times, serif', fontSize: 2, minFontSize: 1.1 },
  { font: 'Impact, Charcoal, sans-serif', fontSize: 2, minFontSize: 1.2 },
  { font: 'Comic Sans MS, Comic Sans, cursive', fontSize: 2, minFontSize: 0.95 },
  { font: 'fonarto', fontSize: 2, minFontSize: 1 },

  { font: 'angeloText', fontSize: 3.2, minFontSize: 1.5 },
  { font: 'Adine', fontSize: 3.5, fontWeight: '600', minFontSize: 2 },
  { font: 'beyondWonderLand', fontSize: 2.5, minFontSize: 1.2 },

  { font: 'GreenFuz', fontSize: 2.2, minFontSize: 1.3 },
  { font: 'Nautilus', fontSize: 2.3, fontStyle: 'italic', minFontSize: 1.2 },
  { font: 'remjoo', fontSize: 2.1, minFontSize: 1 },

  { font: 'Oldwaysr', fontSize: 4.5, minFontSize: 1.8 },
  { font: 'vinque', fontSize: 2.1, minFontSize: 1 },
  { font: 'Arnoia', fontSize: 2.1, minFontSize: 1.1 },

  { font: 'Bad', fontSize: 2.7, minFontSize: 1.5 },
  { font: 'blowbrush', fontSize: 2.3, minFontSize: 1.2 },
  { font: 'DepotFilled', fontSize: 1.5, minFontSize: 0.8 },

  { font: 'DoubleFeature20', fontSize: 1.7, minFontSize: 0.9 },
  { font: 'EraserRegular', fontSize: 1.7, minFontSize: 0.8 },
  { font: 'Fanfarrón', fontSize: 2.1, minFontSize: 1.1 },

  { font: 'Flaemische', fontSize: 2.6, minFontSize: 1.25 },
  { font: 'mexcellent', fontSize: 2, minFontSize: 1.1 },
  { font: 'ParkLaneNF', fontSize: 1.8, minFontSize: 0.95 },


];

const baseFontSizes = {

  'Times New Roman, Times, serif': 2,
  'Impact, Charcoal, sans-serif': 2,
  'Comic Sans MS, Comic Sans, cursive': 1.8,
  'fonarto': 2,
  'angeloText': 3.2,
  'Adine': 3.5,
  'beyondWonderLand': 2.5,
  'GreenFuz': 2.2,
  'Nautilus': 2.3,
  'remjoo': 2.1,
  'Oldwaysr': 4.5,
  'vinque': 2.1,
  'Arnoia': 2.1,
  'Bad': 2.7,
  'blowbrush': 2.3,
  'DepotFilled': 1.5,
  'DoubleFeature20': 1.7,
  'EraserRegular': 1.7,
  'Fanfarrón': 2.1,
  'Flaemische': 2.6,
  'mexcellent': 2,
  'ParkLaneNF': 1.8,

};

const scalingFactor = {

  'Times New Roman, Times, serif': 1,
  'Impact, Charcoal, sans-serif': 1,
  'Comic Sans MS, Comic Sans, cursive': 1.1,
  'fonarto': 1,
  'angeloText': 0.925,
  'Adine': 1,
  'beyondWonderLand': 0.8,
  'GreenFuz': 0.9,
  'Nautilus': 0.9,
  'remjoo': 1,
  'Oldwaysr': 0.8,
  'vinque': 1,
  'Arnoia': 1,
  'Bad': 1,
  'blowbrush': 0.8,
  'DepotFilled': 0.9,
  'DoubleFeature20': 1.1,
  'EraserRegular': 1.1,
  'Fanfarrón': 0.9,
  'Flaemische': 0.9,
  'mexcellent': 1,
  'ParkLaneNF': 1.1,

};

// const colors = [
//   '#000', '#800000', '#008000', '#000080', '#FFBF34', '#A633FF',
// ];
const colors = [
  '#1abc9c',  // $turquoise
  '#e8f8f5',  // $turquoise-50
  '#d1f2eb',  // $turquoise-100
  '#a3e4d7',  // $turquoise-200
  '#76d7c4',  // $turquoise-300
  '#48c9b0',  // $turquoise-400
  '#1abc9c',  // $turquoise-500
  '#17a589',  // $turquoise-600
  '#148f77',  // $turquoise-700
  '#117864',  // $turquoise-800
  '#0e6251',  // $turquoise-900
  '#16a085',  // $green-sea
  '#e8f6f3',  // $green-sea-50
  '#d0ece7',  // $green-sea-100
  '#a2d9ce',  // $green-sea-200
  '#73c6b6',  // $green-sea-300
  '#45b39d',  // $green-sea-400
  '#16a085',  // $green-sea-500
  '#138d75',  // $green-sea-600
  '#117a65',  // $green-sea-700
  '#0e6655',  // $green-sea-800
  '#0b5345',  // $green-sea-900
  '#2ecc71',  // $emerald
  '#eafaf1',  // $emerald-50
  '#d5f5e3',  // $emerald-100
  '#abebc6',  // $emerald-200
  '#82e0aa',  // $emerald-300
  '#58d68d',  // $emerald-400
  '#2ecc71',  // $emerald-500
  '#28b463',  // $emerald-600
  '#239b56',  // $emerald-700
  '#1d8348',  // $emerald-800
  '#186a3b',  // $emerald-900
  '#27ae60',  // $nephritis
  '#e9f7ef',  // $nephritis-50
  '#d4efdf',  // $nephritis-100
  '#a9dfbf',  // $nephritis-200
  '#7dcea0',  // $nephritis-300
  '#52be80',  // $nephritis-400
  '#27ae60',  // $nephritis-500
  '#229954',  // $nephritis-600
  '#1e8449',  // $nephritis-700
  '#196f3d',  // $nephritis-800
  '#145a32',  // $nephritis-900
  '#3498db',  // $peter-river
  '#ebf5fb',  // $peter-river-50
  '#d6eaf8',  // $peter-river-100
  '#aed6f1',  // $peter-river-200
  '#85c1e9',  // $peter-river-300
  '#5dade2',  // $peter-river-400
  '#3498db',  // $peter-river-500
  '#2e86c1',  // $peter-river-600
  '#2874a6',  // $peter-river-700
  '#21618c',  // $peter-river-800
  '#1b4f72',  // $peter-river-900
  '#2980b9',  // $belize-hole
  '#eaf2f8',  // $belize-hole-50
  '#d4e6f1',  // $belize-hole-100
  '#a9cce3',  // $belize-hole-200
  '#7fb3d5',  // $belize-hole-300
  '#5499c7',  // $belize-hole-400
  '#2980b9',  // $belize-hole-500
  '#2471a3',  // $belize-hole-600
  '#1f618d',  // $belize-hole-700
  '#1a5276',  // $belize-hole-800
  '#154360',  // $belize-hole-900
  '#9b59b6',  // $amethyst
  '#f5eef8',  // $amethyst-50
  '#ebdef0',  // $amethyst-100
  '#d7bde2',  // $amethyst-200
  '#c39bd3',  // $amethyst-300
  '#af7ac5',  // $amethyst-400
  '#9b59b6',  // $amethyst-500
  '#884ea0',  // $amethyst-600
  '#76448a',  // $amethyst-700
  '#633974',  // $amethyst-800
  '#512e5f',  // $amethyst-900
  '#8e44ad',  // $wisteria
  '#f4ecf7',  // $wisteria-50
  '#e8daef',  // $wisteria-100
  '#d2b4de',  // $wisteria-200
  '#bb8fce',  // $wisteria-300
  '#a569bd',  // $wisteria-400
  '#8e44ad',  // $wisteria-500
  '#7d3c98',  // $wisteria-600
  '#6c3483',  // $wisteria-700
  '#5b2c6f',  // $wisteria-800
  '#4a235a',  // $wisteria-900
  '#34495e',  // $wet-asphalt
  '#ebedef',  // $wet-asphalt-50
  '#d6dbdf',  // $wet-asphalt-100
  '#aeb6bf',  // $wet-asphalt-200
  '#85929e',  // $wet-asphalt-300
  '#5d6d7e',  // $wet-asphalt-400
  '#34495e',  // $wet-asphalt-500
  '#2e4053',  // $wet-asphalt-600
  '#283747',  // $wet-asphalt-700
  '#212f3c',  // $wet-asphalt-800
  '#1b2631',  // $wet-asphalt-900
  '#2c3e50',  // $midnight-blue
  '#eaecee',  // $midnight-blue-50
  '#d5d8dc',  // $midnight-blue-100
  '#abb2b9',  // $midnight-blue-200
  '#808b96',  // $midnight-blue-300
  '#566573',  // $midnight-blue-400
  '#2c3e50',  // $midnight-blue-500
  '#273746',  // $midnight-blue-600
  '#212f3d',  // $midnight-blue-700
  '#1c2833',  // $midnight-blue-800
  '#17202a',  // $midnight-blue-900
  '#f1c40f',  // $sunflower
  '#fef9e7',  // $sunflower-50
  '#fcf3cf',  // $sunflower-100
  '#f9e79f',  // $sunflower-200
  '#f7dc6f',  // $sunflower-300
  '#f4d03f',  // $sunflower-400
  '#f1c40f',  // $sunflower-500
  '#d4ac0d',  // $sunflower-600
  '#b7950b',  // $sunflower-700
  '#9a7d0a',  // $sunflower-800
  '#7d6608',  // $sunflower-900
  '#f39c12',  // $orange
  '#fef5e7',  // $orange-50
  '#fdebd0',  // $orange-100
  '#fad7a0',  // $orange-200
  '#f8c471',  // $orange-300
  '#f5b041',  // $orange-400
  '#f39c12',  // $orange-500
  '#d68910',  // $orange-600
  '#b9770e',  // $orange-700
  '#9c640c',  // $orange-800
  '#7e5109',  // $orange-900
  '#e67e22',  // $carrot
  '#fdf2e9',  // $carrot-50
  '#fae5d3',  // $carrot-100
  '#f5cba7',  // $carrot-200
  '#f0b27a',  // $carrot-300
  '#eb984e',  // $carrot-400
  '#e67e22',  // $carrot-500
  '#ca6f1e',  // $carrot-600
  '#af601a',  // $carrot-700
  '#935116',  // $carrot-800
  '#784212',  // $carrot-900
  '#d35400',  // $pumpkin
  '#fbeee6',  // $pumpkin-50
  '#f6ddcc',  // $pumpkin-100
  '#edbb99',  // $pumpkin-200
  '#e59866',  // $pumpkin-300
  '#dc7633',  // $pumpkin-400
  '#d35400',  // $pumpkin-500
  '#ba4a00',  // $pumpkin-600
  '#a04000',  // $pumpkin-700
  '#873600',  // $pumpkin-800
  '#6e2c00',  // $pumpkin-900
  '#e74c3c',  // $alizarin
  '#fdedec',  // $alizarin-50
  '#fadbd8',  // $alizarin-100
  '#f5b7b1',  // $alizarin-200
  '#f1948a',  // $alizarin-300
  '#ec7063',  // $alizarin-400
  '#e74c3c',  // $alizarin-500
  '#cb4335',  // $alizarin-600
  '#b03a2e',  // $alizarin-700
  '#943126',  // $alizarin-800
  '#78281f',  // $alizarin-900
  '#c0392b',  // $pomegranate
  '#f9ebea',  // $pomegranate-50
  '#f2d7d5',  // $pomegranate-100
  '#e6b0aa',  // $pomegranate-200
  '#d98880',  // $pomegranate-300
  '#cd6155',  // $pomegranate-400
  '#c0392b',  // $pomegranate-500
  '#a93226',  // $pomegranate-600
  '#922b21',  // $pomegranate-700
  '#7b241c',  // $pomegranate-800
  '#641e16',  // $pomegranate-900
  '#ecf0f1',  // $clouds
  '#fbfcfc',  // $clouds-50
  '#f7f9f9',  // $clouds-100
  '#eef2f3',  // $clouds-200
  '#e0e6e8',  // $clouds-300
  '#ccd1d4',  // $clouds-400
  '#ecf0f1',  // $clouds-500
  '#d0d3d4',  // $clouds-600
  '#b3b6b7',  // $clouds-700
  '#979a9a',  // $clouds-800
  '#7b7d7d',  // $clouds-900
  '#bdc3c7',  // $silver
  '#f8f9f9',  // $silver-50
  '#f2f3f4',  // $silver-100
  '#e5e8e8',  // $silver-200
  '#d7dbdd',  // $silver-300
  '#cacfd2',  // $silver-400
  '#bdc3c7',  // $silver-500
  '#a6acaf',  // $silver-600
  '#909497',  // $silver-700
  '#797d7f',  // $silver-800
  '#626567',  // $silver-900
  '#95a5a6',  // $concrete
  '#f4f6f6',  // $concrete-50
  '#eaeded',  // $concrete-100
  '#d5dbdb',  // $concrete-200
  '#bfc9ca',  // $concrete-300
  '#aab7b8',  // $concrete-400
  '#95a5a6',  // $concrete-500
  '#839192',  // $concrete-600
  '#717d7e',  // $concrete-700
  '#5f6a6a',  // $concrete-800
  '#4d5656',  // $concrete-900
  '#7f8c8d',  // $asbestos
  '#f2f4f4',  // $asbestos-50
  '#e5e8e8',  // $asbestos-100
  '#ccd1d1',  // $asbestos-200
  '#b2babb',  // $asbestos-300
  '#99a3a4',  // $asbestos-400
  '#7f8c8d',  // $asbestos-500
  '#707b7c',  // $asbestos-600
  '#616a6b',  // $asbestos-700
  '#515a5a',  // $asbestos-800
  '#424949',  // $asbestos-900
];

const primaryColors = [
  '#1abc9c', '#16a085', '#2ecc71', '#27ae60', '#3498db', '#2980b9', '#9b59b6', '#8e44ad', '#34495e', '#2c3e50',
  '#f1c40f', '#f39c12', '#e67e22', '#d35400', '#e74c3c', '#c0392b', '#ecf0f1', '#bdc3c7', '#95a5a6', '#7f8c8d'
];

const shuffleArray = (array) => {
  const shuffled = array.slice();
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const filterAndReorderColors = (logos) => {
  const specifiedColorLogos = logos.filter(logo =>
    primaryColors.some(color => logo.props.children.props.style.color === color)
  );
  const otherLogos = logos.filter(logo =>
    !primaryColors.some(color => logo.props.children.props.style.color === color)
  );
  return [...specifiedColorLogos, ...otherLogos];
};

const useRandomStyleTextLogos = (logoText, isInitials) => {
  const [textLogos, setLogos] = useState([]);
  const maxFontSize = '2.3rem';
  const minFontSize = '1rem';


  // let adjustedFontSize;
  // if (logoText.length <= 10) {
  //   adjustedFontSize = maxFontSize;
  // }
  // else if (logoText.length > 10 && logoText.length <= 20) {

  //   adjustedFontSize = '1.7rem'; // Middle value

  // }
  // else {

  //   adjustedFontSize = minFontSize;

  // }

  function getAdjustedFontSize(font, logoText, maxFontSize, minFontSize) {
    let baseSize = baseFontSizes[font];
    let scaleFactor = scalingFactor[font];
    let adjustedFontSize;

    if (logoText.length <= 10) {
      adjustedFontSize = maxFontSize;
    } else if (logoText.length > 10 && logoText.length <= 20) {
      adjustedFontSize = ((maxFontSize + minFontSize) / 2) * scaleFactor;
    } else {
      adjustedFontSize = minFontSize;
    }
    //console.log('adjustedFontSize', adjustedFontSize)
    return `${adjustedFontSize}rem`;
  }


  useEffect(() => {
    const generateLogos = (getfonts) => {
      const newLogos = getfonts.flatMap((font) =>
        colors.map((color) => (
          <div style={{ textAlign: 'center', padding: '20px' }} key={`${font.font}-${color}`}>
            
            <div
              style={{
                fontFamily: font.font,
                color: color,
                fontSize: isInitials ? '3rem' : getAdjustedFontSize(font.font, logoText, font.fontSize, font.minFontSize),
                fontStyle: font.fontStyle,
                fontWeight: font.fontWeight,
                margin: '0 2px',
                textWrap: 'nowrap'
              }}
            >
              {logoText}
            </div>
          </div>
        ))
      );
      const shuffledLogos = shuffleArray(newLogos);
      const orderedLogos = filterAndReorderColors(shuffledLogos);
      setLogos(orderedLogos);
      //setLogos(shuffleArray(newLogos));
    };


    isInitials ? generateLogos(initialsFonts) : generateLogos(fonts);
  }, [logoText]);

  return textLogos; // Return logos directly as an array
};

export default useRandomStyleTextLogos;


// export default RandomStyleTextLogos;


export const RandomStyleInitialLogos = (logotext) => {

  const [initials, setInitials] = useState('');

  useEffect(() => {
    if (logotext) {
      const newInitials = text.split(' ').map(item => item[0]).join('');
      setInitials(newInitials);
    }
  }, [text]);

  return (

    <div >
      {initials}
    </div>

  );
};

import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import styles from "./SampleLogos.module.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { downloadImage } from '../utils/downloadImage';
import Download from "../assets/Download.svg";
import Edit from "../assets/Edit.svg";
import { Link } from 'react-router-dom';
import CustomPagination from '../components/CustomPagination';
import Pagination from '../components/Pagination';
import useGenerateLogoComponents from '../components/IconLogoGenerator';
import useGenerateBadgeComponents from '../components/BadgeStyleLogos';
import useRandomStyleTextLogos from '../components/TextLogoGenerator';
import { LogoContext } from '../contexts/LogoGeneratorContext';

const RandomStyleInitialLogos = (logoText = '') => {
  if (typeof logoText !== 'string') {
    console.error('Invalid logoText provided:', logoText);
    return '';
  }

  return logoText.split(' ').map(item => item[0]).join('');
};
const Frame15 = () => {

  const [activeIndexes, setActiveIndexes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const htmlDivElementRef = useRef();
  const { selectedFonts, selectedLayouts, logoStyles, logoText, logoIndustry } = useContext(LogoContext);
  const refs = useRef([]);
  const [logoComponents, setLogoComponents] = useState([]);
  const memoizedSelectedFonts = useMemo(() => selectedFonts, [selectedFonts]);
  const memoizedSLogoText = useMemo(() => logoText, []);
  const memoizedIndustry = useMemo(() => logoIndustry, []);

  const textLogos = useRandomStyleTextLogos(logoText, false);
  const iconLogos = useGenerateLogoComponents(memoizedSelectedFonts, selectedLayouts, logoText,logoIndustry, logoStyles);
  const badgeLogos = useGenerateBadgeComponents(memoizedSelectedFonts, memoizedSLogoText,memoizedIndustry);
  const initials = RandomStyleInitialLogos(logoText);
  const initialLogos = useRandomStyleTextLogos(initials, true);

  const handleCardClick = (index) => {
    setActiveIndexes((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter(i => i !== index)
        : [...prevIndexes, index]
    );
  };

  useEffect(() => {
    console.log(`Active index changed: ${activeIndexes}`);
  }, [activeIndexes]);



  useEffect(() => {
    let newLogoComponents = [];
  
    if (logoStyles.includes('Text')) {
      newLogoComponents = [...newLogoComponents, ...textLogos];
    }
    
    if (logoStyles.includes('Icon') || logoStyles.includes('Geometry')) {
      newLogoComponents = [...newLogoComponents, ...iconLogos];
    }
  
    if (logoStyles.includes('Initials')) {
      newLogoComponents = [...newLogoComponents, ...initialLogos];
    }
  
    if (logoStyles.includes('Badge')) {
      newLogoComponents = [...newLogoComponents, ...badgeLogos];
    }
  
    setLogoComponents(newLogoComponents);
  }, [logoStyles, iconLogos,  textLogos, initialLogos, badgeLogos]);

  useEffect(() => {

  if (isMobile) {
    setItemsPerPage(4)
  } else {
    setItemsPerPage(12)
  }

  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDownload = (ref) => {
    downloadImage(ref.current);
  };


  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDownloadClick = (logo, index) => {
    console.log("Function called")
    const link = document.createElement('a');
    link.href = logo;
    link.download = `logo-${index + 1}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const currentLogos = isMobile
  //   ? logos.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
  //   : logos;
  const currentLogos = logoComponents.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  // const currentLogos = useMemo(() => 
  //   logoComponents.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
  //   [logoComponents, currentPage, itemsPerPage]
  // );
  

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>


      <Navbar />


      <div className={styles.logoContainer}>
        <div className={styles.chooseALogoToEditOrSaveParent}>
          <div className={styles.chooseALogo}>Choose a logo to edit or save</div>
          <div className={styles.paragraph}>
            Save a design as is or click edit to change colors, fonts, and more.
          </div>
        </div>


        <div className={styles.frameDiv}>
          {isMobile ? (
            <div className={styles.component20Parent}>
              {currentLogos.map((logo, logoIndex) => {
                const globalIndex = (currentPage - 1) * itemsPerPage + logoIndex;
                return (
                  <>

                    <div
                      className={`${styles.component20} ${activeIndexes.includes(globalIndex) ? styles.active : ''}`}
                      key={logoIndex}
                      onClick={() => handleCardClick(globalIndex)}
                    >

                      <div className={`${styles.content} ${activeIndexes.includes(globalIndex) ? styles.activeColor : ''}`}>
                        {/* <img className={styles.icon} alt={`Logo ${logoIndex + 1}`} src={logo} /> */}
                        <div ref={refs.current[globalIndex]}>
                          {logo}

                        </div>
                        <div className="d-flex justify-content-center align-items-center flex-row" style={{ gap: "0.4rem", marginTop:'-90px'}}>
                          <div className={`${styles.editIcon} ${activeIndexes.includes(globalIndex) ? styles.visible : ''}`} style={{ cursor: 'pointer' }}>
                            <Link to='/steps' ><img src={Edit} alt="Edit" /></Link>
                          </div>
                          <div className={`${styles.downloadIcon} ${activeIndexes.includes(globalIndex) ? styles.visible : ''}`} style={{ cursor: 'pointer' }} >
                            <img
                              src={Download}
                              alt="Download"
                              style={{ cursor: 'pointer' }}
                              onClick=
                              {(e) => {
                                e.stopPropagation(); // Prevent the card click event
                                handleDownload(refs.current[globalIndex]);
                              }}
                            />

                          </div>
                        </div>

                      </div>
                    </div>
                  </>
                );
              })}
            </div>

          ) :

            (

              Array.from({ length: Math.ceil(currentLogos.length / 4) }, (_, rowIndex) => (
                <div className={styles.component20Parent} key={rowIndex}>
                  {currentLogos.slice(rowIndex * 4, (rowIndex + 1) * 4).map((logo, logoIndex) => {
                    const globalIndex = rowIndex * 4 + logoIndex;
                    refs.current[globalIndex] = refs.current[globalIndex] || React.createRef();
                    return (
                      <div
                        className={`${styles.component20} ${activeIndexes.includes(globalIndex) ? styles.active : ''}`}
                        key={logoIndex}
                        onClick={() => handleCardClick(globalIndex)}
                      >
                        <div className={`${styles.content} ${activeIndexes.includes(globalIndex) ? styles.activeColor : ''}`}>
                          {/* <img className={styles.icon} alt={`Logo ${logoIndex + 1}`} src={logo} /> */}
                          <div ref={refs.current[globalIndex]}>
                            {logo}

                          </div>
                          <div className="d-flex justify-content-center align-items-center flex-row" style={{ zIndex: '3', gap: "0.4rem", marginTop:'-90px' }}>
                            <div className={`${styles.editIcon} ${activeIndexes.includes(globalIndex) ? styles.visible : ''}`} style={{ cursor: 'pointer' }}>
                              <Link to='/steps'><img src={Edit} alt="Edit" /></Link>
                            </div>
                            <div className={`${styles.downloadIcon} ${activeIndexes.includes(globalIndex) ? styles.visible : ''}`} style={{ cursor: 'pointer' }}>
                              <img
                                src={Download}
                                alt="Download"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent the card click event

                                  handleDownload(refs.current[globalIndex]);
                                }}
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                    );
                  })}
                </div>
              ))
            )}
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '3.75rem' }}>
          {/* <Pagination count={Math.ceil(logos.length / itemsPerPage)} page={currentPage} onChange={handleChange} shape="rounded" /> */}
          {/* <CustomPagination count={Math.ceil(logos.length / itemsPerPage)} page={currentPage} onChange={handleChange} /> */}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={logoComponents.length}
            pageSize={itemsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>


      </div>



      <Footer />
    </div>
  );
};

export default Frame15;
